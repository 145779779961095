import { Delete } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import { useState } from 'react'
import ConfirmationModal, {
  CustomInput,
  CustomSelect,
  selectTypes,
} from 'src/consumer/components/helperComponents'
import { FEATURE_BUNDLE_TYPES_ARRAY } from 'src/consumer/constants'
import { confirmationModalDefaultProps } from 'src/consumer/helpers/utilFunctions'

export default function FeatureBundleComponent({
  bundle,
  changeBundle,
  deleteBundle,
  disabled,
  subscriptionPlansToUse,
}) {
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    confirmationModalDefaultProps,
  )

  function handleDelete() {
    setConfirmationModalProps({
      ...confirmationModalProps,
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      onSubmitFunctions: [deleteBundle],
      action: 'delete',
      body: 'Are you sure you want to remove this bundle from the list?',
      buttonColor: 'error',
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CustomSelect
            id="type"
            label="Bundle Type"
            value={bundle?.type}
            options={FEATURE_BUNDLE_TYPES_ARRAY}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.title}
            placeholder="Select bundle type..."
            onChangeEvent={changeBundle}
            disabled={disabled}
            selectType={selectTypes.string}
            required
          />
        </Grid>
        {bundle?.type === FEATURE_BUNDLE_TYPES_ARRAY?.[0]?.id && (
          <Grid id="maxCountInput" item xs={12} md={6}>
            <CustomInput
              id="maxCount"
              label="Number of purchasable features"
              value={bundle.maxCount}
              onChange={changeBundle}
              placeholder="Enter a number..."
              disabled={disabled}
              required
            />
          </Grid>
        )}
        {bundle?.type === FEATURE_BUNDLE_TYPES_ARRAY?.[1]?.id && (
          <Grid item xs={12} md={6}>
            <CustomInput
              id="featureAndCreditValidityInDays"
              label="Feature and Credit Validity In Days"
              value={bundle?.featureAndCreditValidityInDays}
              onChange={changeBundle}
              type="number"
              placeholder="Enter validity in days..."
              disabled={disabled}
              required
            />
          </Grid>
        )}
        {/* <Grid item xs={12} md={6}>
          <CustomReactSelect
            id="metaCategoryType"
            label="Feature Bundle Category Type"
            value={bundle?.metaCategoryType}
            options={store.metaCategoryTypes
              ?.filter((type) => type?.scope === 'app')
              ?.sort((a, b) => a?.title?.localeCompare(b?.title))}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.title}
            placeholder="Select feature bundle meta category type..."
            onChangeEvent={changeBundle}
            disabled={disabled}
            selectType={selectTypes.string}
            required
          />
        </Grid>
        {bundle?.metaCategoryType && (
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="metaCategory"
              label="Feature Bundle Category"
              value={bundle?.metaCategory}
              options={store.categories
                ?.filter((cat) => cat?.categoryType === bundle?.metaCategoryType)
                ?.sort((a, b) => a?.title?.localeCompare(b?.title))}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.title}
              placeholder="Select feature bundle category..."
              onChangeEvent={changeBundle}
              disabled={disabled}
              selectType={selectTypes.string}
              required
            />
          </Grid>
        )}
        {bundle?.metaCategory && bundle?.metaCategoryType !== 'app-subscription' && (
          <Grid item xs={12}>
            <label>Features</label>
            <ActivityItemAsyncSelect
              isMulti
              cacheUniqs={[bundle?.metaCategoryType, bundle?.metaCategory]}
              classNamePrefix="activity-item-select"
              menuPortalTarget={document.body}
              value={bundle?.featureIds}
              loadOptions={loadCategoryTypeBasedItems}
              isDisabled={disabled}
              debounceTimeout={1000}
              additional={{
                page: 0,
                size: 200,
                metaCategoryTypeId: bundle?.metaCategoryType,
                categoryIds: bundle?.metaCategory ? [bundle?.metaCategory, ''] : undefined,
              }}
              onChange={(selVal) => changeBundle({ target: { id: 'featureIds', value: selVal } })}
            />
          </Grid>
        )} */}
        <Grid item xs={12}>
          <Grid item xs={12} md={6}>
            <CustomSelect
              id="pricingPackId"
              label="Plan to give"
              value={bundle?.pricingPackId}
              options={subscriptionPlansToUse}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.title}
              placeholder="Select subscription plan..."
              onChangeEvent={changeBundle}
              disabled={disabled}
              selectType={selectTypes.string}
              required
            />
          </Grid>
          {bundle?.pricingPackId && (
            <Grid item xs={12} md={6}>
              <CustomSelect
                id="featureIds"
                label="Events"
                value={bundle?.featureIds}
                options={[]} //mentor events
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.title}
                placeholder="Select an event..."
                onChangeEvent={changeBundle}
                disabled={disabled}
                selectType={selectTypes.string}
                required
              />
            </Grid>
          )}
          {/* <label>Pricing Pack Id</label>
            <AsyncPaginate
              cacheUniqs={[bundle?.featureIds]}
              classNamePrefix="activity-item-select"
              menuPortalTarget={document.body}
              value={bundle?.pricingPackId}
              loadOptions={loadOptionsForAsyncPaginate}
              debounceTimeout={500}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.title}
              additional={{
                getFunction: GetSettingsData,
                apiUrl: GENERIC_APIS.entity.getPaginated,
                otherParams: {
                  entityType: ALL_ENTITY_TYPES.consumerSubscriptionPlan,
                },
              }}
              onChange={(selVal) => changeBundle({ target: { id: 'pricingPackId', value: selVal } })}
            /> */}
        </Grid>
        {deleteBundle && !disabled && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleDelete} color="error" startIcon={<Delete />}>
              Delete Bundle
            </Button>
          </Grid>
        )}
      </Grid>
      <ConfirmationModal {...confirmationModalProps} newImplementation />
    </>
  )
}

import moment from 'moment'
import { useEffect, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import { withAsyncPaginate } from 'react-select-async-paginate'
import Creatable from 'react-select/creatable'
import { RectangleRadioButton } from 'src/clinic/components/ClinicManageBookingsPage/ClinicManageBookingComponents'
import { getCPDataWithoutParams } from 'src/clinic/services'
import {
  GENDER_LIST_ONBOARDING
} from 'src/consumer/constants'
import { getAgeFromDobWithTime } from 'src/consumer/helpers/profilePageHelper'
import {
  PMPFooterContainer,
  PMPHeaderDetails,
  PMPHeadingBanner,
  PMPSectionInputBox,
  PMPSectionInputLabel,
  PMPSectionTextAreaBox
} from '../PrescriptionMakingPage/PrescriptionMakingPageStyles'

export function getDateOfTherapyReport(therapyReportData, withTime) {
  let format = 'DD/MM/YYYY'
  if (withTime) {
    format = 'DD/MM/YYYY h:mmA'
  }
  if (therapyReportData?.createdTime) {
    return moment.utc(therapyReportData?.createdTime || therapyReportData?.modifiedTime, 'YYYY-MM-DD HH:mm:ss').local().format(format)
  }
  return moment().format(format)
}

export const TherapyReportHeader = ({ therapyReportData, therapistName }) => {
  return (
    <div style={{ background: '#FFF' }}>
      <PMPHeadingBanner noColor={true}>Therapy Report</PMPHeadingBanner>
      <PMPHeaderDetails noColor={true}>
        <div>
          Patient Name:{' '}
          <span style={{ textTransform: 'capitalize' }}>{therapyReportData?.patientName}</span>
        </div>
        <div>
          Age/Gender: {getAgeFromDobWithTime(therapyReportData?.patientDateOfBirth)}/
          {
            GENDER_LIST_ONBOARDING?.find((item) => item.value === therapyReportData?.patientGender)
              ?.label
          }
        </div>
        <div>Date : {getDateOfTherapyReport(therapyReportData)}</div>
        {therapistName && <div>Therapist : {therapistName}</div>}
      </PMPHeaderDetails>
    </div>
  )
}

export const TherapyReportBodyFirstSection = ({
  observationAndAdvice,
  selectedComplaints,
  selectedDiagnosis,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '20px' }}>
      {observationAndAdvice?.observation && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Observations</div>
          <div>{observationAndAdvice?.observation}</div>
        </div>
      )}
      {Array.isArray(selectedComplaints) && selectedComplaints?.length > 0 && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Complaints</div>
          <div>{selectedComplaints.map((complaint) => complaint.label).join(', ')}</div>
        </div>
      )}
      {Array.isArray(selectedDiagnosis) && selectedDiagnosis?.length > 0 && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Diagnosis</div>
          <div>{selectedDiagnosis.map((diagnosisItem) => diagnosisItem.label).join(', ')}</div>
        </div>
      )}
    </div>
  )
}

export const TherapyReportBody = ({
  value,
  setValue,
  placeholder,
  alsoChangePropValue,
  setPropValue,
  observationAndAdvice,
  nextVisitInputType,
  nextVisitInputValue,
}) => {
  const quillRef = useRef(null)
  // useEffect(() => {
  //     if (quillRef.current) {
  //         // Focus on the Quill editor's content area
  //         quillRef.current.focus();
  //     }
  // }, []);
  useEffect(() => {
    if (alsoChangePropValue) {
      setPropValue(value)
    }
  }, [value])
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '20px' }}>
      {observationAndAdvice?.advice && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Advice</div>
          <div>{observationAndAdvice?.advice}</div>
        </div>
      )}
      {nextVisitInputValue && nextVisitInputType && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Next Visit Date</div>
          <div>{calculateNextVisitDate(nextVisitInputValue, nextVisitInputType)}</div>
        </div>
      )}
      <ReactQuill
        key={placeholder}
        ref={quillRef}
        theme="bubble"
        value={value}
        onChange={setValue}
        placeholder={placeholder}
        customStyles={['<style>.ql-editor { padding: 0; }</style>']}
      />
    </div>
  )
}

export const TherapyReportFooter = ({ therapyReportData }) => {
  return (
    <PMPFooterContainer noColor={true}>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '40%' }}
      >
        <div style={{ textAlign: 'right' }}>{therapyReportData?.footer?.headline}</div>
        <div style={{ textAlign: 'right' }}>{therapyReportData?.footer?.subtext}</div>
        <div style={{ textAlign: 'right' }}>{therapyReportData?.footer?.supportText}</div>
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}
      >
        <div>{therapyReportData?.footer?.headlineAlt}</div>
        <div>{therapyReportData?.footer?.subtextAlt}</div>
        <div>{therapyReportData?.footer?.supportTextAlt}</div>
      </div>
    </PMPFooterContainer>
  )
}

/////////////////////////////////////////////////////////////////////// NEW prescription drug selection related components:

const commonStyles = {
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '200px',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    '::-webkit-scrollbar': {
      width: '5px',
    },
    '::-webkit-scrollbar-track': {
      background: '#fafafa',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    backgroundColor: state.menuIsOpen ? '#fff8dc' : '#FFF',
    border: state.isFocused ? '1px solid green' : provided.border,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid green',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none', // Hide the default arrow separator
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none', // Hide the default dropdown arrow
  }),
}

////////////////////////////////////////////////////////////////////////// NEW prescription components

export const PrescriptionTextAreaComponent = ({ inputValue, setInputValue }) => {
  return (
    <PMPSectionTextAreaBox value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
  )
}

const loadOptionsComplaints = async (search, loadedOptions, { page, clinicId }) => {
  const response = await getCPDataWithoutParams(
    `/api/secure/doctor/health-complaint/list?search=${search}&clinicId=${clinicId}&page=${page}&pageSize=5`,
  )
  var hasMore = true
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.title,
        label: option?.title,
      }
    })
  } else {
    hasMore = false
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
    },
  }
}

const loadOptionsDiagnosis = async (search, loadedOptions, { page, clinicId }) => {
  const response = await getCPDataWithoutParams(
    `/api/secure/doctor/diagnosis/list?search=${search}&clinicId=${clinicId}&page=${page}&pageSize=5`,
  )
  var hasMore = true
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.title,
        label: option?.title,
      }
    })
  } else {
    hasMore = false
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
    },
  }
}

export const ComplaintsSelectMenu = ({ clinicId, selectedComplaints, setSelectedComplaints }) => {
  const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

  return (
    <div style={{ width: '100%' }}>
      <CreatableAsyncPaginate
        id="complaintsSelection"
        placeholder=""
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        defaultOptions={false}
        loadOptions={loadOptionsComplaints}
        getOptionValue={(option) => option?.value}
        getOptionLabel={(option) => option?.label}
        value={selectedComplaints}
        onChange={(value) => setSelectedComplaints(value)}
        debounceTimeout={500}
        additional={{ page: 1, clinicId: clinicId }}
        styles={commonStyles}
      />
    </div>
  )
}

export const DiagnosisSelectMenu = ({ clinicId, selectedDiagnosis, setSelectedDiagnosis }) => {
  const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

  return (
    <div style={{ width: '100%' }}>
      <CreatableAsyncPaginate
        id="diagnosisSelection"
        placeholder=""
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        defaultOptions={false}
        loadOptions={loadOptionsDiagnosis}
        getOptionValue={(option) => option?.value}
        getOptionLabel={(option) => option?.label}
        value={selectedDiagnosis}
        onChange={(value) => {
          setSelectedDiagnosis(value)
        }}
        debounceTimeout={500}
        additional={{ page: 1, clinicId: clinicId }}
        styles={commonStyles}
      />
    </div>
  )
}

export const PrescriptionNextVisitComponent = ({
  nextVisitInputType,
  setNextVisitInputType,
  nextVisitInputValue,
  setNextVisitInputValue,
}) => {
  let formattedNextDate

  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <PMPSectionInputLabel>Next Visit</PMPSectionInputLabel>
      <div style={{ width: '40px' }}>
        <PMPSectionInputBox
          type="number"
          value={nextVisitInputValue}
          onChange={(e) => setNextVisitInputValue(e.target.value)}
        />
      </div>
      <div style={{ width: '200px' }}>
        <RectangleRadioButton
          disabled={false}
          options={[
            { label: 'Days', id: 'days' },
            { label: 'Weeks', id: 'weeks' },
            { label: 'Months', id: 'months' },
          ]}
          selectedId={nextVisitInputType}
          selectionFunction={(id) => setNextVisitInputType(id)}
        />
      </div>
      {nextVisitInputValue && nextVisitInputType && (
        <div>({calculateNextVisitDate(nextVisitInputValue, nextVisitInputType)})</div>
      )}
    </div>
  )
}

///// helper functions:

// complaints array related:

export function getComplaintsArrayForSubmission(complaintsArray, clinicId) {
  const toReturn = []
  if (Array.isArray(complaintsArray) && complaintsArray.length > 0) {
    complaintsArray?.forEach((complaintItem) => {
      if (complaintItem?.label) {
        toReturn.push({
          apiParam: 'health-complaint',
          clinicId: clinicId,
          title: complaintItem?.label,
        })
      }
    })
  }
  return toReturn
}

export const getInitialDataForSettingExistingSelectedComplaints = (complaintsFromServer) => {
  const toReturn = []
  if (Array.isArray(complaintsFromServer) && complaintsFromServer.length > 0) {
    complaintsFromServer?.forEach((item) => {
      toReturn.push({ label: item?.title, value: item?.title })
    })
  }
  return toReturn
}

// diagnosis array related:

export function getDiagnosisArrayForSubmission(diagnosisArray, clinicId) {
  const toReturn = []
  if (Array.isArray(diagnosisArray) && diagnosisArray.length > 0) {
    diagnosisArray?.forEach((diagnosisItem) => {
      if (diagnosisItem?.label) {
        toReturn.push({
          apiParam: 'diagnosis',
          clinicId: clinicId,
          title: diagnosisItem?.label,
        })
      }
    })
  }
  return toReturn
}

export const getInitialDataForSettingExistingSelectedDiagnosis = (diagnosisFromServer) => {
  const toReturn = []
  if (Array.isArray(diagnosisFromServer) && diagnosisFromServer.length > 0) {
    diagnosisFromServer?.forEach((item) => {
      toReturn.push({ label: item?.title, value: item?.title })
    })
  }
  return toReturn
}

// next date related

export const calculateNextVisitDate = (nextVisitInputValue, nextVisitInputType, format) => {
  const currentDate = moment() // Get the current date

  // Map the input type to the corresponding moment method
  const methodMap = {
    days: 'add',
    months: 'add',
    weeks: 'add',
  }

  // Calculate the next visit date based on input type and value
  if (methodMap[nextVisitInputType]) {
    currentDate[methodMap[nextVisitInputType]](nextVisitInputValue, nextVisitInputType)
  } else {
    console.error('Invalid input type')
  }

  if (format) {
    return currentDate.format(format)
  }
  // Return the formatted date (you can adjust the format based on your needs)
  return currentDate.format('DD/MM/YYYY')
}

export const getNextVisitDateForSubmission = (
  nextVisitInputValue,
  nextVisitInputType,
  nextVisitCalendarInput,
) => {
  const currentDate = moment() // Get the current date

  // Map the input type to the corresponding moment method
  const methodMap = {
    days: 'add',
    months: 'add',
    weeks: 'add',
  }

  // Calculate the next visit date based on input type and value
  if (methodMap[nextVisitInputType]) {
    currentDate[methodMap[nextVisitInputType]](nextVisitInputValue, nextVisitInputType)
  } else {
    console.error('Invalid input type')
  }
  return currentDate?.format('YYYY-MM-DDTHH:mm:ss[Z]')
}

export const getInitialDataForSettingDaysOfNextVisit = (nextDate, createdTime) => {
  if (nextDate) {
    if (createdTime) {
      return moment?.(nextDate)?.diff(moment(createdTime, 'YYYY-MM-DD HH:mm:ss'), 'days')
    } else {
      return moment?.(nextDate)?.diff(moment(), 'days')
    }
  }
  return null
}

// latestvitalid in prescriptionData

// next visit
// threapy chosen
// therpast chosen from list
// therpast chosen manually
// new complaints fire api, id in complaints
// new diagnosis fire api, id in diagnosis
// QR : need something like this : https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=https://aumhum.com/mentor/aviral-pandey/events/talk-to-aviral

// decide what to do with save, print and save as it is taking a lot of time
// something to do with all these fields : newly created drug is not showing up
// if "genericName" coming, then show

// find solution 1: rerendering and slowing down fix - typing
// find solution 2:

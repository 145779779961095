import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import 'react-quill/dist/quill.bubble.css'
import { useLocation } from 'react-router-dom'
import { withAsyncPaginate } from 'react-select-async-paginate'
import AsyncSelect from 'react-select/async'
import { default as Creatable, default as CreatableSelect } from 'react-select/creatable'
import { RectangleRadioButton } from 'src/clinic/components/ClinicManageBookingsPage/ClinicManageBookingComponents'
import { sortArrayMomentBased } from 'src/clinic/components/ClinicManageBookingsPage/ClinicManageBookingsHelper'
import { getCPDataWithoutParams } from 'src/clinic/services'
import { AutocompleteHelperButton } from 'src/consumer/components/helperComponents'
import {
  DOSAGE_OPTIONS,
  DURATION_OPTIONS_PRESCRIPTION,
  NOTES_OPTIONS,
  PRESCRIPTION_LAYOUTS,
  frequencyOptions,
  whatTimeOptions,
} from 'src/consumer/constants'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import { getMPDataWithoutParams, postMPDataWithoutParams } from 'src/mentor/services'
import {
  PMPSectionInputBox,
  PMPSectionInputLabel,
  PMPSectionTextAreaBox,
  PMPSingleRowBox,
  PMPSingleRowForDrugs,
} from './PrescriptionMakingPageStyles'
import { DefaultNextVisitRowComponent } from './prescriptionTemplate/body/DefaultBodyComponents'
import { ThreeColumnNextVisitRowComponent } from './prescriptionTemplate/body/ThreeColumnFooterBodyComponents'
import DefaultPrescriptionFooter from './prescriptionTemplate/footer/DefaultPrescriptionFooter'
import ThreeColumnPrescriptionFooter from './prescriptionTemplate/footer/ThreeColumnPrescriptionFooter'
import DefaultPrescriptionHeader from './prescriptionTemplate/header/DefaultPrescriptionHeader'
import ThreeColumnPrescriptionHeader from './prescriptionTemplate/header/ThreeColumnPrescriptionHeader'

export function getDateOfPrescription(prescriptionData, withTime) {
  let format = 'DD/MM/YYYY'
  if (withTime) {
    format = 'DD/MM/YYYY h:mmA'
  }
  if (prescriptionData?.prescriptionData?.createdTime) {
    return moment
      .utc(
        prescriptionData?.prescriptionData?.createdTime ||
          prescriptionData?.prescriptionData?.modifiedTime,
        'YYYY-MM-DD HH:mm:ss',
      )
      .local()
      .format(format)
  }
  return moment().format(format)
}

export async function putAllDrugsInDB() {
  // if (isDrugDatabaseUpdateNeeded()){
  //   try {
  //     const response = await getCPDataWithoutParams(
  //       '/api/secure/drug/list?search=&clinicId=CLN-ee233e80966946948dea2b518719081b&clinicBrandId=sakra-world-brand&page=1&pageSize=100',
  //     )
  //     const responseData = response?.data
  //     const indexedDB =
  //       window.indexedDB ||
  //       window.mozIndexedDB ||
  //       window.webkitIndexedDB ||
  //       window.msIndexedDB ||
  //       window.shimIndexedDB
  //     const dbRequest = indexedDB.open('amhmDB', 1)
  //     dbRequest.onerror = function (event) {
  //       console.error('IndexedDB error:', event.target.errorCode)
  //     }
  //     dbRequest.onupgradeneeded = function () {
  //       const db = dbRequest.result
  //       if (!db?.objectStoreNames?.contains('drugs')) {
  //         const store = db?.createObjectStore('drugs', { keyPath: 'id' })
  //         store.createIndex('title', ['title'], { unique: false })
  //       }
  //     }
  //     dbRequest.onsuccess = function () {
  //       const db = dbRequest.result
  //       const transaction = db.transaction('drugs', 'readwrite')
  //       const store = transaction.objectStore('drugs')
  //       const clearRequest = store.clear()
  //       clearRequest.onsuccess = function () {
  //         console.log('IDB-PUT: cleared old data')
  //         responseData.forEach((drug) => {
  //           store.put(drug)
  //         })
  //         console.log('IDB-PUT: New Data successfully stored')
  //         sessionStorage.setItem("drugDatabaseUpdated", moment().format())
  //       }
  //       clearRequest.onerror = function (event) {
  //         console.log('IDB-PUT: could not clear old data')
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Putting all drugs in client DB failed:', error)
  //   }
  // } else {
  //   console.log('IDB-PUT: Drugs database update not needed.')
  // }
}

export function readAllDrugDataFromDB() {
  return null
}
//   return new Promise((resolve, reject) => {
//     const indexedDB =
//       window.indexedDB ||
//       window.mozIndexedDB ||
//       window.webkitIndexedDB ||
//       window.msIndexedDB ||
//       window.shimIndexedDB

//     const dbRequest = indexedDB.open('DrugData', 1)

//     dbRequest.onerror = function (event) {
//       console.error('IndexedDB error:', event.target.errorCode)
//       reject(event.target.errorCode)
//     }

//     dbRequest.onsuccess = function () {
//       const db = dbRequest.result
//       const transaction = db.transaction('drugs', 'readonly')
//       const store = transaction.objectStore('drugs')

//       const getAllRequest = store.getAll()

//       getAllRequest.onsuccess = function () {
//         const drugs = getAllRequest.result
//         resolve(drugs)
//       }

//       getAllRequest.onerror = function (event) {
//         console.error('Error retrieving data from IndexedDB:', event.target.errorCode)
//         reject(event.target.errorCode)
//       }
//     }
//   })
// }

export const PrescriptionHeader = ({ prescriptionData, viewingType, printType }) => {
  const HeaderComponent = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.threeColumnFooter:
        return ThreeColumnPrescriptionHeader
      default:
        return DefaultPrescriptionHeader
    }
  }, [prescriptionData?.layout])

  return (
    <HeaderComponent
      prescriptionData={prescriptionData}
      viewingType={viewingType}
      printType={printType}
    />
  )
}

export const PrescriptionDrugAdviceTable = ({ drugsAdvice }) => {
  const filteredDrugsAdvice = drugsAdvice?.filter((item) => item?.drug?.label)
  if (filteredDrugsAdvice?.length > 0) {
    return (
      <>
        <div style={{ paddingTop: '10px', fontWeight: 600, fontSize: '30px' }}>Rx</div>
        <PMPSingleRowForDrugs reduceLastBox style={{ width: '100%', margin: '0 auto' }}>
          <PMPSingleRowBox borderRight>Medicine</PMPSingleRowBox>
          <PMPSingleRowBox borderRight>Dose</PMPSingleRowBox>
          <PMPSingleRowBox borderRight>When</PMPSingleRowBox>
          <PMPSingleRowBox borderRight>Frequency</PMPSingleRowBox>
          <PMPSingleRowBox borderRight>Duration</PMPSingleRowBox>
          {/* <PMPSingleRowBox borderRight>Starting</PMPSingleRowBox> */}
          <PMPSingleRowBox borderRight>Notes</PMPSingleRowBox>
        </PMPSingleRowForDrugs>
        {filteredDrugsAdvice?.map((drugAdviceItem, drugAdviceItemIndex) => {
          return (
            <PMPSingleRowForDrugs
              reduceLastBox
              key={drugAdviceItemIndex}
              style={{ width: '100%', margin: '0 auto' }}
            >
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.drug?.label}
              </PMPSingleRowBox>
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.dosage?.label}
              </PMPSingleRowBox>
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.whatTime?.label}
              </PMPSingleRowBox>
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.frequency?.label}
              </PMPSingleRowBox>
              {/* <PMPSingleRowBox whiteBG borderRight>{drugAdviceItem?.duration?.label}</PMPSingleRowBox> */}
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.duration?.label}
              </PMPSingleRowBox>
              <PMPSingleRowBox whiteBG borderRight>
                {drugAdviceItem?.notes?.label}
              </PMPSingleRowBox>
            </PMPSingleRowForDrugs>
          )
        })}
      </>
    )
  } else {
    return null
  }
}

export const PrescriptionBodyFirstSection = ({
  observationAndAdvice,
  selectedComplaints,
  selectedDiagnosis,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {observationAndAdvice?.observation && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Observations</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{observationAndAdvice?.observation}</div>
        </div>
      )}
      {Array.isArray(selectedComplaints) && selectedComplaints?.length > 0 && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Complaints</div>
          <div>{selectedComplaints.map((complaint) => complaint.label).join(', ')}</div>
        </div>
      )}
      {Array.isArray(selectedDiagnosis) && selectedDiagnosis?.length > 0 && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Diagnosis</div>
          <div>{selectedDiagnosis.map((diagnosisItem) => diagnosisItem.label).join(', ')}</div>
        </div>
      )}
    </div>
  )
}

export const PrescriptionBody = ({
  value,
  setValue,
  placeholder,
  alsoChangePropValue,
  setPropValue,
  observationAndAdvice,
  selectedTherapies,
  nextVisitInputType,
  nextVisitInputValue,
  viewingType,
  prescriptionData,
  suggestedBookingTherapy,
}) => {
  const quillRef = useRef(null)

  const NextVisitColumn = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.threeColumnFooter:
        return ThreeColumnNextVisitRowComponent
      default:
        return DefaultNextVisitRowComponent
    }
  }, [prescriptionData?.layout])

  // useEffect(() => {
  //     if (quillRef.current) {
  //         // Focus on the Quill editor's content area
  //         quillRef.current.focus();
  //     }
  // }, []);

  useEffect(() => {
    if (alsoChangePropValue) {
      setPropValue(value)
    }
  }, [value])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '20px' }}>
      {observationAndAdvice?.advice && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Advice</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{observationAndAdvice?.advice}</div>
        </div>
      )}
      {suggestedBookingTherapy && (
        <div style={{ fontWeight: '500', fontStyle: 'italic' }}>
          Therapy Recommended: {suggestedBookingTherapy ? 'Yes' : 'No'}
        </div>
      )}
      {}
      {Array.isArray(selectedTherapies) && selectedTherapies.length > 0 && (
        <div>
          <div style={{ fontWeight: '500', fontStyle: 'italic' }}>Suggested Therapies:</div>
          <div>{selectedTherapies?.map((item) => item?.label)?.join(', ')}</div>
        </div>
      )}
      <NextVisitColumn
        nextVisitInputType={nextVisitInputType}
        nextVisitInputValue={nextVisitInputValue}
        prescriptionData={prescriptionData}
      />
      {/* {!Boolean(viewingType) && (
        <ReactQuill
          key={placeholder}
          ref={quillRef}
          theme="bubble"
          value={value}
          onChange={setValue}
          placeholder={placeholder}
          customStyles={['<style>.ql-editor { padding: 0; }</style>']}
        />
      )} */}
    </div>
  )
}

export const PrescriptionFooter = ({ prescriptionData, viewingType, eventId, printType }) => {
  const location = useLocation()
  const queryString = location.search
  const urlParams = new URLSearchParams(queryString)
  const showQr = urlParams.get('show-qr') || false

  const FooterComponent = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.threeColumnFooter:
        return ThreeColumnPrescriptionFooter
      default:
        return DefaultPrescriptionFooter
    }
  }, [prescriptionData?.layout])

  return (
    <FooterComponent
      prescriptionData={prescriptionData}
      noColor={true}
      eventId={eventId}
      viewingType={viewingType}
      printType={printType}
      hideQr={!showQr}
    />
  )
}

/////////////////////////////////////////////////////////////////////// NEW prescription drug selection related components:

const commonStyles = {
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '200px',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    '::-webkit-scrollbar': {
      width: '5px',
    },
    '::-webkit-scrollbar-track': {
      background: '#fafafa',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    backgroundColor: state.menuIsOpen ? '#fff8dc' : '#FFF',
    border: state.isFocused ? '1px solid green' : provided.border,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid green',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none', // Hide the default arrow separator
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none', // Hide the default dropdown arrow
  }),
}

const loadOptionsDrugs = async (search, loadedOptions, { page, clinicId, clinicBrandId }) => {
  const response = await getCPDataWithoutParams(
    `/api/secure/drug/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}&pageSize=5`,
  )
  var hasMore = true
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.title,
        label: option?.title,
      }
    })
  } else {
    hasMore = false
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
    },
  }
}

export const DrugSelectMenu = ({ clinicId, clinicBrandId, selectedDrug, setSelectedDrug }) => {
  const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

  return (
    <CreatableAsyncPaginate
      id="drugSelection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      defaultOptions={false}
      loadOptions={loadOptionsDrugs}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      value={selectedDrug}
      onChange={(value) => {
        setSelectedDrug(value)
      }}
      debounceTimeout={500}
      additional={{ page: 1, clinicId: clinicId, clinicBrandId: clinicBrandId }}
      styles={commonStyles}
    />
  )
}

export const DosageSelectMenu = ({ allowEnteringValue, selectedDosage, setSelectedDosage }) => {
  const selectOptions = DOSAGE_OPTIONS

  return (
    <CreatableSelect
      id="dosageSelection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      options={selectOptions}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      value={selectedDosage}
      onChange={(value) => {
        allowEnteringValue && setSelectedDosage(value)
      }}
      styles={commonStyles}
    />
  )
}

export const WhatTimeSelectMenu = ({
  allowEnteringValue,
  selectedWhatTime,
  setSelectedWhatTime,
}) => {
  const optionsArray = whatTimeOptions?.map((option, index) => {
    return { value: option?.title, label: option?.title }
  })

  return (
    <CreatableSelect
      id="whatTimeSelection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      options={optionsArray}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      value={selectedWhatTime}
      onChange={(value) => {
        allowEnteringValue && setSelectedWhatTime(value)
      }}
      styles={commonStyles}
    />
  )
}

export const FrequencySelectMenu = ({
  allowEnteringValue,
  selectedFrequency,
  setSelectedFrequency,
}) => {
  const optionsArray = frequencyOptions?.map((option, index) => {
    return { value: option?.title, label: option?.title }
  })

  return (
    <CreatableSelect
      id="frequencySelection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      options={optionsArray}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      value={selectedFrequency}
      onChange={(value) => {
        allowEnteringValue && setSelectedFrequency(value)
      }}
      styles={commonStyles}
    />
  )
}

export const DurationSelectMenu = ({
  allowEnteringValue,
  selectedDurationTime,
  setSelectedDurationTime,
}) => {
  const optionsArray = DURATION_OPTIONS_PRESCRIPTION?.map((option, index) => {
    return { value: option?.label, label: option?.label }
  })

  return (
    <CreatableSelect
      id="durationSelection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      options={optionsArray}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      value={selectedDurationTime}
      onChange={(value) => {
        allowEnteringValue && setSelectedDurationTime(value)
      }}
      styles={commonStyles}
    />
  )
}

export const NotesSelectMenu = ({ allowEnteringValue, selectedNote, setSelectedNote }) => {
  const optionsArray = NOTES_OPTIONS?.map((option, index) => {
    return { value: option?.label, label: option?.label }
  })

  return (
    <CreatableSelect
      id="notesSelection"
      placeholder=""
      isClearable={true}
      isSearchable={true}
      options={optionsArray}
      getOptionValue={(option) => option?.value}
      getOptionLabel={(option) => option?.label}
      value={selectedNote}
      onChange={(value) => {
        allowEnteringValue && setSelectedNote(value)
      }}
      styles={commonStyles}
    />
  )
}

////////////////////////////////////////////////////////////////////////// NEW prescription components

export const PrescriptionTextAreaComponent = ({ inputValue, setInputValue }) => {
  return (
    <PMPSectionTextAreaBox value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
  )
}

const loadOptionsComplaints = async (search, loadedOptions, { page, clinicId, clinicBrandId }) => {
  const response = await getCPDataWithoutParams(
    `/api/secure/doctor/health-complaint/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}&pageSize=5`,
  )
  var hasMore = true
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.title,
        label: option?.title,
      }
    })
  } else {
    hasMore = false
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
    },
  }
}

const loadOptionsDiagnosis = async (search, loadedOptions, { page, clinicId, clinicBrandId }) => {
  const response = await getCPDataWithoutParams(
    `/api/secure/doctor/diagnosis/list?search=${search}&clinicId=${clinicId}&clinicBrandId=${clinicBrandId}&page=${page}&pageSize=5`,
  )
  var hasMore = true
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.title,
        label: option?.title,
      }
    })
  } else {
    hasMore = false
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
    },
  }
}

const loadOptionsSuggestedTherapies = async (search, loadedOptions, { page, clinicId }) => {
  const response = await getCPDataWithoutParams(
    `/api/secure/technique/paginated/list/searchable?search=${search}&page=${page}&pageSize=5`,
  )
  var hasMore = true
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.title,
        label: option?.title,
      }
    })
  } else {
    hasMore = false
  }

  return {
    options: optionsArray,
    hasMore,
    additional: {
      page: page + 1,
      clinicId,
    },
  }
}

const loadOptionsTherapists = async () => {
  const response = await getCPDataWithoutParams(
    `/api/external/mentor/1on1/list?categories=clinical_psychologist&page=1&pageSize=100`,
  )
  var optionsArray = []

  if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
    optionsArray = response?.data?.map((option, index) => {
      return {
        ...option,
        value: option?.id,
        label: option?.name,
      }
    })
  }
  return optionsArray
}

export const ComplaintsSelectMenu = ({
  clinicId,
  clinicBrandId,
  selectedComplaints,
  setSelectedComplaints,
}) => {
  const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

  return (
    <div style={{ width: '100%' }}>
      <CreatableAsyncPaginate
        id="complaintsSelection"
        placeholder=""
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        defaultOptions={false}
        loadOptions={loadOptionsComplaints}
        getOptionValue={(option) => option?.value}
        getOptionLabel={(option) => option?.label}
        value={selectedComplaints}
        onChange={(value) => setSelectedComplaints(value)}
        debounceTimeout={500}
        additional={{ page: 1, clinicId: clinicId, clinicBrandId: clinicBrandId }}
        styles={commonStyles}
      />
    </div>
  )
}

export const DiagnosisSelectMenu = ({
  clinicId,
  clinicBrandId,
  selectedDiagnosis,
  setSelectedDiagnosis,
}) => {
  const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

  return (
    <div style={{ width: '100%' }}>
      <CreatableAsyncPaginate
        id="diagnosisSelection"
        placeholder=""
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        defaultOptions={false}
        loadOptions={loadOptionsDiagnosis}
        getOptionValue={(option) => option?.value}
        getOptionLabel={(option) => option?.label}
        value={selectedDiagnosis}
        onChange={(value) => {
          setSelectedDiagnosis(value)
        }}
        debounceTimeout={500}
        additional={{ page: 1, clinicId: clinicId, clinicBrandId: clinicBrandId }}
        styles={commonStyles}
      />
    </div>
  )
}

export const PrescriptionNextVisitComponent = ({
  nextVisitInputType,
  setNextVisitInputType,
  nextVisitInputValue,
  setNextVisitInputValue,
}) => {
  let formattedNextDate

  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <PMPSectionInputLabel>Next Visit</PMPSectionInputLabel>
      <div style={{ width: '40px' }}>
        <PMPSectionInputBox
          type="number"
          value={nextVisitInputValue}
          onChange={(e) => setNextVisitInputValue(e.target.value)}
        />
      </div>
      <div style={{ width: '200px' }}>
        <RectangleRadioButton
          disabled={false}
          options={[
            { label: 'Days', id: 'days' },
            { label: 'Weeks', id: 'weeks' },
            { label: 'Months', id: 'months' },
          ]}
          selectedId={nextVisitInputType}
          selectionFunction={(id) => setNextVisitInputType(id)}
        />
      </div>
      {nextVisitInputValue && nextVisitInputType && (
        <div>({calculateNextVisitDate(nextVisitInputValue, nextVisitInputType)})</div>
      )}
    </div>
  )
}

export const SuggestedTherapySelectMenu = ({
  clinicId,
  selectedTherapies,
  setSelectedTherapies,
}) => {
  const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

  return (
    <div style={{ width: '100%' }}>
      <CreatableAsyncPaginate
        id="suggestedTherapiesSelection"
        placeholder=""
        isMulti={true}
        isClearable={true}
        isSearchable={true}
        defaultOptions={false}
        loadOptions={loadOptionsSuggestedTherapies}
        getOptionValue={(option) => option?.value}
        getOptionLabel={(option) => option?.label}
        value={selectedTherapies}
        onChange={(value) => {
          setSelectedTherapies(value)
        }}
        debounceTimeout={500}
        additional={{ page: 1, clinicId: clinicId }}
        styles={commonStyles}
      />
    </div>
  )
}

export const TherapistSelectMenu = ({ selectedTherapist, setSelectedTherapist }) => {
  return (
    <div style={{ width: '100%' }}>
      <AsyncSelect
        id="therapistSelection"
        defaultOptions={true}
        placeholder=""
        isClearable={true}
        isSearchable={true}
        loadOptions={loadOptionsTherapists}
        styles={commonStyles}
        getOptionValue={(option) => option?.value}
        getOptionLabel={(option) => option?.label}
        value={selectedTherapist}
        onChange={(value) => {
          setSelectedTherapist(value)
        }}
      />
    </div>
  )
}

///// helper functions:

// drugs related:
export function handleDrugAdviceChange(toChange, indexToChange, newValue, setDrugsAdvice) {
  if (toChange === 'drug') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].drug = newValue
      return prevArray
    })
  } else if (toChange === 'dosage') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].dosage = newValue
      return prevArray
    })
  } else if (toChange === 'whatTime') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].whatTime = newValue
      return prevArray
    })
  } else if (toChange === 'frequency') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].frequency = newValue
      return prevArray
    })
  } else if (toChange === 'duration') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].duration = newValue
      return prevArray
    })
  } else if (toChange === 'notes') {
    setDrugsAdvice((prev) => {
      const prevArray = [...prev]
      prevArray[indexToChange].notes = newValue
      return prevArray
    })
  }
}

export function autoSuggestDrugAdviceUsingDrug(indexToChange, newValue, setDrugsAdvice) {
  setDrugsAdvice((prev) => {
    const newArray = [...prev]
    const newObject = newArray?.[indexToChange]
    if (
      Boolean(newObject?.dosage) ||
      Boolean(newObject?.duration) ||
      Boolean(newObject?.notes) ||
      Boolean(newObject?.frequency) ||
      Boolean(newObject?.whatTime)
    ) {
    } else {
      newObject.dosage = newValue?.dosage
        ? { value: newValue?.dosage, label: newValue?.dosage }
        : null
      newObject.duration = newValue?.timing
        ? { value: newValue?.timing, label: newValue?.timing }
        : null
      newObject.notes = newValue?.note ? { value: newValue?.note, label: newValue?.note } : null
      newObject.frequency = newValue?.frequency
        ? { value: newValue?.frequency, label: newValue?.frequency }
        : null
      newObject.whatTime = newValue?.whatTime
        ? { value: newValue?.whatTime, label: newValue?.whatTime }
        : null
      newArray[indexToChange] = newObject
    }
    return newArray
  })
}

export function nullAllValuesOfDrugAdvice(indexToChange, setDrugsAdvice) {
  setDrugsAdvice((prev) => {
    const prevArray = [...prev]
    prevArray[indexToChange] = {
      drug: null,
      dosage: null,
      whatTime: null,
      frequency: null,
      duration: null,
      notes: null,
    }
    return prevArray
  })
}

export function handleDeleteDrugAdviceItem(deletedItemIndex, setDrugsAdvice) {
  setDrugsAdvice((prev) => {
    const prevArray = [...prev]
    prevArray.splice(deletedItemIndex, 1)
    return prevArray
  })
}

export function handleAddDrugAdviceItem(setDrugsAdvice) {
  setDrugsAdvice((prev) => {
    const prevArray = [...prev]
    prevArray.push({
      drug: null,
      dosage: null,
      whatTime: null,
      frequency: null,
      duration: null,
      notes: null,
    })
    return prevArray
  })
}

export function getDrugsListArrayForSubmission(drugsAdvice, clinicId, clinicBrandId) {
  const toReturn = []
  if (Array.isArray(drugsAdvice) && drugsAdvice.length > 0) {
    drugsAdvice?.forEach((drugAdviceItem) => {
      if (drugAdviceItem?.drug?.label) {
        toReturn.push({
          apiParam: 'drug-info',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          id: drugAdviceItem?.drug?.id ?? null,
          name: drugAdviceItem?.drug?.label ?? null,
          dosage: drugAdviceItem?.dosage?.label ?? null,
          whatTime: drugAdviceItem?.whatTime?.label ?? null,
          frequency: drugAdviceItem?.frequency?.label ?? null,
          timing: drugAdviceItem?.duration?.label ?? null,
          note: drugAdviceItem?.notes?.label ?? null,
        })
      }
    })
  }
  return toReturn
}

export async function saveNewDrugs(drugsAdvice, clinicId, clinicBrandId) {
  const toReturn = []
  if (Array.isArray(drugsAdvice) && drugsAdvice.length > 0) {
    drugsAdvice?.forEach((drugAdviceItem) => {
      if (drugAdviceItem?.drug?.label && drugAdviceItem?.drug?.__isNew__) {
        toReturn.push({
          apiParam: 'drug-info',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          name: drugAdviceItem?.drug?.label ?? null,
          dosage: drugAdviceItem?.dosage?.label ?? null,
          whatTime: drugAdviceItem?.whatTime?.label ?? null,
          frequency: drugAdviceItem?.frequency?.label ?? null,
          timing: drugAdviceItem?.duration?.label ?? null,
          note: drugAdviceItem?.notes?.label ?? null,
        })
      }
    })
  }
  toReturn.forEach(async (item) => {
    await postMPDataWithoutParams(
      `/api/secure/drug/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
      item,
    )
  })
  return toReturn
}

export async function saveNewHealthComplaints(healthComplaints, clinicId, clinicBrandId) {
  const toReturn = []
  if (Array.isArray(healthComplaints) && healthComplaints.length > 0) {
    healthComplaints?.forEach((healthComplaintItem) => {
      if (healthComplaintItem?.label && healthComplaintItem?.__isNew__) {
        toReturn.push({
          apiParam: 'health-complaint',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          title: healthComplaintItem?.label ?? null,
        })
      }
    })
  }
  toReturn.forEach(async (item) => {
    await postMPDataWithoutParams(
      `/api/secure/doctor/health-complaint/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
      item,
    )
  })
}

export async function saveNewDiagnosis(diagnosis, clinicId, clinicBrandId) {
  const toReturn = []
  if (Array.isArray(diagnosis) && diagnosis.length > 0) {
    diagnosis?.forEach((diagnosisItem) => {
      if (diagnosisItem?.label && diagnosisItem?.__isNew__) {
        toReturn.push({
          apiParam: 'diagnosis',
          clinicId: clinicId,
          clinicBrandId: clinicBrandId,
          title: diagnosisItem?.label ?? null,
        })
      }
    })
  }
  toReturn.forEach(async (item) => {
    await postMPDataWithoutParams(
      `/api/secure/doctor/diagnosis/save?clinicId=${clinicId}&clinicBrandId=${clinicBrandId}`,
      item,
    )
  })
}

export const getInitialDataForSettingExistingDrugAdvice = (existingData) => {
  const toReturn = []
  if (Array.isArray(existingData) && existingData.length > 0) {
    existingData?.forEach((item) => {
      toReturn.push({
        drug: item?.title ? { label: item?.title, value: item?.title } : null,
        dosage: item?.dosage ? { label: item?.dosage, value: item?.dosage } : null,
        whatTime: item?.whatTime ? { label: item?.whatTime, value: item?.whatTime } : null,
        frequency: item?.frequency ? { label: item?.frequency, value: item?.frequency } : null,
        duration: item?.timing ? { label: item?.timing, value: item?.timing } : null,
        notes: item?.note ? { label: item?.note, value: item?.note } : null,
      })
    })
  }
  return toReturn
}

// complaints array related:

export function getComplaintsArrayForSubmission(complaintsArray, clinicId) {
  const toReturn = []
  if (Array.isArray(complaintsArray) && complaintsArray.length > 0) {
    complaintsArray?.forEach((complaintItem) => {
      if (complaintItem?.label) {
        toReturn.push({
          apiParam: 'health-complaint',
          clinicId: clinicId,
          title: complaintItem?.label,
        })
      }
    })
  }
  return toReturn
}

export const getInitialDataForSettingExistingSelectedComplaints = (complaintsFromServer) => {
  const toReturn = []
  if (Array.isArray(complaintsFromServer) && complaintsFromServer.length > 0) {
    complaintsFromServer?.forEach((item) => {
      toReturn.push({ label: item?.title, value: item?.title })
    })
  }
  return toReturn
}

// diagnosis array related:

export function getDiagnosisArrayForSubmission(diagnosisArray, clinicId) {
  const toReturn = []
  if (Array.isArray(diagnosisArray) && diagnosisArray.length > 0) {
    diagnosisArray?.forEach((diagnosisItem) => {
      if (diagnosisItem?.label) {
        toReturn.push({
          apiParam: 'diagnosis',
          clinicId: clinicId,
          title: diagnosisItem?.label,
        })
      }
    })
  }
  return toReturn
}

export const getInitialDataForSettingExistingSelectedDiagnosis = (diagnosisFromServer) => {
  const toReturn = []
  if (Array.isArray(diagnosisFromServer) && diagnosisFromServer.length > 0) {
    diagnosisFromServer?.forEach((item) => {
      toReturn.push({ label: item?.title, value: item?.title })
    })
  }
  return toReturn
}

// therpaies needed array related:

export function getSelectedTherapiesArrayForSubmission(selectedTherapies) {
  const toReturn = []
  if (Array.isArray(selectedTherapies) && selectedTherapies.length > 0) {
    selectedTherapies?.forEach((selectedTherapy) => {
      toReturn.push(selectedTherapy?.label)
    })
  }
  return toReturn
}

export const getInitialDataForSettingExistingSelectedTherapies = (therapiesFromServer) => {
  const toReturn = []
  if (Array.isArray(therapiesFromServer) && therapiesFromServer.length > 0) {
    therapiesFromServer?.forEach((item) => {
      toReturn.push({ label: item, value: item })
    })
  }
  return toReturn
}

export const getInitialDataForSettingSelectedTherapist = async (therapist) => {
  const response = await getCPDataWithoutParams(
    `/api/external/mentor/1on1/list?categories=clinical_psychologist&page=1&pageSize=100`,
  )
  if (response.status === 200) {
    const selectedTherapist = response.data.find(
      (therapistFromList) => therapistFromList?.id === therapist?.id,
    )
    if (selectedTherapist?.name) {
      return { value: selectedTherapist?.id, label: selectedTherapist?.name }
    }
    return null
  }
}

// next date related

export const calculateNextVisitDate = (nextVisitInputValue, nextVisitInputType, format) => {
  const currentDate = moment() // Get the current date

  // Map the input type to the corresponding moment method
  const methodMap = {
    days: 'add',
    months: 'add',
    weeks: 'add',
  }

  // Calculate the next visit date based on input type and value
  if (methodMap[nextVisitInputType]) {
    currentDate[methodMap[nextVisitInputType]](nextVisitInputValue, nextVisitInputType)
  } else {
    console.error('Invalid input type')
  }

  if (format) {
    return currentDate.format(format)
  }
  // Return the formatted date (you can adjust the format based on your needs)
  return currentDate.format('DD/MM/YYYY')
}

export const getNextVisitDateForSubmission = (
  nextVisitInputValue,
  nextVisitInputType,
  nextVisitCalendarInput,
) => {
  if (nextVisitInputValue && nextVisitInputType) {
    const currentDate = moment() // Get the current date

    // Map the input type to the corresponding moment method
    const methodMap = {
      days: 'add',
      months: 'add',
      weeks: 'add',
    }

    // Calculate the next visit date based on input type and value
    if (methodMap[nextVisitInputType]) {
      currentDate[methodMap[nextVisitInputType]](nextVisitInputValue, nextVisitInputType)
    } else {
      console.error('Invalid input type')
    }
    return currentDate?.format('YYYY-MM-DDTHH:mm:ss[Z]')
  }
}

export const getInitialDataForSettingDaysOfNextVisit = (nextDate, createdTime) => {
  if (nextDate) {
    if (createdTime) {
      return moment?.(nextDate)?.diff(moment(createdTime, 'YYYY-MM-DD HH:mm:ss'), 'days')
    } else {
      return moment?.(nextDate)?.diff(moment(), 'days')
    }
  }
  return null
}

// latestvitalid in prescriptionData

// next visit
// threapy chosen
// therpast chosen from list
// therpast chosen manually
// new complaints fire api, id in complaints
// new diagnosis fire api, id in diagnosis

// decide what to do with save, print and save as it is taking a lot of time
// something to do with all these fields : newly created drug is not showing up
// if "genericName" coming, then show

// find solution 1: rerendering and slowing down fix - typing
// find solution 2:

function isDrugDatabaseUpdateNeeded() {
  const dataLastUpdated = sessionStorage.getItem('drugDatabaseUpdated')
  if (dataLastUpdated) {
    const momentObj = moment(dataLastUpdated)
    var isSameDate = momentObj.isSame(moment(), 'day')
    return !isSameDate
  }
  return true
}

export const AutoFillFromLastPrescriptionComponent = ({
  patientId,
  thisBookedEventId,
  clickFunction,
}) => {
  const [pastPrescriptionsLoading, setPastPrescriptionsLoading] = useState(false)
  const [lastPrescriptionData, setLastPrescriptionData] = useState(null)

  async function setupThings() {
    setPastPrescriptionsLoading(true)
    const response = await getMPDataWithoutParams(
      `/api/secure/doctor/prescription/patient/list?patientId=${patientId}`,
    )
    setPastPrescriptionsLoading(false)
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      const prescriptionDataList = response?.data?.data?.prescriptionDataList || []
      sortArrayMomentBased(prescriptionDataList, 'modifiedTime', false)
      const resultArray = prescriptionDataList?.map(
        (pastPrecriptionsDataOnly, pastPrecriptionsDataOnlyIndex) => ({
          ...(response?.data?.data?.prescriptionTemplateList?.[0] || {}),
          prescriptionData: pastPrecriptionsDataOnly,
        }),
      )
      const filteredResult = resultArray?.filter(
        (item) => item?.prescriptionData?.bookedEventId !== thisBookedEventId,
      )?.[0]
      setLastPrescriptionData(filteredResult)
    }
  }

  useEffect(() => {
    setupThings()
  }, [])

  function handleOnClick() {
    clickFunction(lastPrescriptionData)
  }

  return (
    <>
      {pastPrescriptionsLoading ? (
        <div style={{ margin: '8px 0' }}>
          <Spinner animation="border" variant="dark" size="sm" />
          <span>Checking if past prescriptions are available</span>
        </div>
      ) : (
        <div style={{ margin: '32px 0 16px' }}>
          {lastPrescriptionData ? (
            <>
              <AutocompleteHelperButton
                text={'Autofill from last Prescription'}
                Icon={AutoFixHighIcon}
                iconProps={{ fontSize: '8px' }}
                clickFunction={handleOnClick}
              />
              <div style={{ fontSize: '12px', marginLeft: '12px' }}>
                from{' '}
                {moment
                  .utc(lastPrescriptionData?.createdTime || lastPrescriptionData?.modifiedTime)
                  ?.local()
                  ?.format('DD/MM/YYYY h:mmA')}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  )
}

import { configureStore } from '@reduxjs/toolkit'
import { businessLayoutReducer } from './business/reducers/layoutReducer'
import { serviceProvidersDataReducer } from './clinic/reducers/serviceProviders/serviceProvidersReducer'
import { genericDataReducer } from './consumer/reducers/genericData/genericDataReducer'
import { personalDataReducer } from './consumer/reducers/personalData/personalDataReducer'
import { bookingRequestReducer } from './mentor/reducers/bookingRequest/bookingRequestReducer'
import { coursesReducer } from './mentor/reducers/courses/coursesReducer'

export default configureStore({
  reducer: {
    genericDataStore: genericDataReducer,
    personalDataStore: personalDataReducer,
    bookingRequestStore: bookingRequestReducer,
    businessLayoutStore: businessLayoutReducer,
    serviceProvidersDataStore: serviceProvidersDataReducer,
    coursesReducer,
  },
  devTools: true,
})

// import { createStore } from 'redux'

// const initialState = {

// DONE:
//   oneOnOneMentorsData: null, //hw
//   categoriesList: null, // hw
//   tagsList: null, // hw
//   userCreditsData: null, //hw
//   userPurchasesData: null, // hw
//   profileData: null,
//   setupData: null,
//   assessmentGoBackState: null,
//   agoraChatServerConnection: null, // hw
//   agoraChatId: null, //hw
//   agoraChatToken: null, // hw
//   receivedBookingRequests: [],

//   sidebarShow: true,
//   asideShow: false,
//   sidebarUnfoldable: true,
//   theme: 'default',

// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     case ADD_BOOKING_REQUEST:
//       return {
//         ...state,
//         receivedBookingRequests: [
//           ...(state?.receivedBookingRequests || []),
//           rest.newReceivedBookingRequest,
//         ],
//       }
//     case DELETE_BOOKING_REQUEST:
//       const oldArray = [...(state?.receivedBookingRequests || [])]
//       const newArray = oldArray.filter((item) => item?.id !== rest.bookingRequestIdToRemove || '')
//       return { ...state, receivedBookingRequests: newArray }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store

import { ExpandMore } from '@mui/icons-material'
import { Collapse, Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'
import { getFaq } from 'src/consumer/services/getFaq'
import { MUIThemeConfig } from 'src/context/MUIThemeConfig'
import { useScreenSize } from 'src/customHooks/customHooks'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import { formFAQDataWithRespectiveFAQCategories } from '../../../faq_utils'
import FAQItem from '../../common/FAQItem'
import { FAQ_LAYOUTS } from '../../utils'
import FAQCategoryContainerWrapper from './FAQCategoryContainerWrapper'
import FAQItemsContainerWrapper from './FAQItemsContainerWrapper'
import {
  FAQCategoriesContainerWithDropDownButton,
  FAQCategoryItem,
  FAQSidebarLayoutContainer,
  FAQSidebarLayoutFAQsContainer,
} from './styledComponent'

export default function SidebarLayout({ faqCategories, loadingCategories, layoutType }) {
  const [faqData, setFaqData] = useState()
  const [loading, setLoading] = useState(true)
  const [openFAQData, setOpenFAQData] = useState({
    index: -1,
    categoryId: null,
  })

  const [expandCategoriesSection, setExpandCategoriesSection] = useState(false)

  const { width, height } = useScreenSize()

  const isMobile = width <= MUIThemeConfig.breakpoints.values.md

  const selectedFaqCategory = faqData?.find((faqData) => faqData?.id === openFAQData?.categoryId)

  function selectFaqCategory(category) {
    getFAQsBasedOnSelectedFaqCategory(category)
    setOpenFAQData((prev) => ({ ...prev, categoryId: category?.id }))
    setExpandCategoriesSection(false)
  }

  function handleExpandCategory() {
    setExpandCategoriesSection((prev) => !prev)
  }

  function openFaqItem(index) {
    setOpenFAQData((prev) => ({ ...prev, index }))
  }

  async function getFAQsBasedOnSelectedFaqCategory(categoryToFetch) {
    setLoading(true)
    let searchString = ''
    if (categoryToFetch) {
      searchString = categoryToFetch?.id
    }
    if (searchString) {
      let response = await getFaq(searchString, { withCategoryDetails: false, size: 999 })
      if (response?.status === 200 && isCommonResponseSuccessful(response.data.code)) {
        const faqData = response?.data?.data?.faqPage?.content
        const faqCategoriesData = faqCategories
        const formedFaqData = formFAQDataWithRespectiveFAQCategories(faqData, faqCategoriesData)

        const faqCategoryUpdated = formedFaqData?.find(
          (faqCat) => faqCat?.id === categoryToFetch?.id,
        )

        setFaqData((prev) =>
          prev?.map((faqCat) => (faqCat?.id === categoryToFetch?.id ? faqCategoryUpdated : faqCat)),
        )
        setOpenFAQData((prev) => ({ ...prev, index: 0 }))
      } else {
        console.log('Error getting FaqData')
      }
    }
  }

  useEffect(() => {
    setFaqData(faqCategories?.map((cat) => ({ ...cat, faqs: [] })))
  }, [faqCategories])

  useEffect(() => {
    if (faqData && openFAQData?.categoryId === null) {
      selectFaqCategory(faqCategories?.[0])
    }
  }, [faqData])

  return (
    <FAQSidebarLayoutContainer>
      <h3 className="heading">FAQs</h3>
      <FAQSidebarLayoutFAQsContainer>
        <FAQCategoriesContainerWithDropDownButton>
          {!!isMobile && (
            <FAQCategoryItem
              disableRipple
              className="d-flex align-items-center justify-content-between"
              onClick={handleExpandCategory}
              style={{ borderBottom: 'unset' }}
            >
              <h2 className="category-name">{selectedFaqCategory?.title}</h2>
              <ExpandMore
                sx={{
                  transform: expandCategoriesSection ? 'rotate(180deg)' : 'unset',
                  transition: 'all 150ms ease-in-out',
                }}
              />
            </FAQCategoryItem>
          )}
          <Collapse in={!isMobile ? true : expandCategoriesSection}>
            <FAQCategoryContainerWrapper layoutType={layoutType}>
              {faqData?.map((category) => (
                <FAQCategoryItem
                  selected={selectedFaqCategory?.id === category?.id}
                  key={category?.id}
                  onClick={() => selectFaqCategory(category)}
                >
                  <h2 className="category-name">{category?.title}</h2>
                </FAQCategoryItem>
              ))}
            </FAQCategoryContainerWrapper>
          </Collapse>
        </FAQCategoriesContainerWithDropDownButton>
        <FAQItemsContainerWrapper layoutType={layoutType}>
          {selectedFaqCategory?.faqs?.length > 0 ? (
            selectedFaqCategory?.faqs?.map((faq, idx) => (
              <FAQItemViewController
                key={faq?.id}
                item={faq}
                itemIndex={idx}
                handleOpenFAQ={openFaqItem}
                isExpanded={idx === openFAQData?.index}
                layoutType={layoutType}
                screenWidth={width}
              />
            ))
          ) : (
            <>
              <ExpandedFAQSkeleton />

              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
              <CollapsedFAQSkeleton layoutType={layoutType} screenWidth={width} />
            </>
          )}
        </FAQItemsContainerWrapper>
      </FAQSidebarLayoutFAQsContainer>
    </FAQSidebarLayoutContainer>
  )
}

function FAQItemViewController({
  item,
  itemIndex,
  handleOpenFAQ,
  isExpanded,
  layoutType,
  screenWidth,
}) {
  const isPC = screenWidth > MUIThemeConfig.breakpoints.values.xl

  switch (layoutType) {
    case FAQ_LAYOUTS.TWO_COLUMN_SIDEBAR:
      return (
        <FAQItem
          item={item}
          itemIndex={itemIndex}
          handleOpenFAQ={handleOpenFAQ}
          isExpanded={isPC ? true : isExpanded}
          hideExpandIcon={isPC}
          lockExpand={isPC}
        />
      )
    default:
      return (
        <FAQItem
          item={item}
          itemIndex={itemIndex}
          handleOpenFAQ={handleOpenFAQ}
          isExpanded={isExpanded}
        />
      )
  }
}

function ExpandedFAQSkeleton() {
  return (
    <div className="w-100 d-flex justify-content-center ">
      <Skeleton variant="rectangular" width="100%" height={200} sx={{ borderRadius: '10px' }} />
    </div>
  )
}

function CollapsedFAQSkeleton({ layoutType, screenWidth }) {
  const isPC = screenWidth > MUIThemeConfig.breakpoints.values.xl

  function defaultRender() {
    return (
      <div className="w-100 d-flex justify-content-center ">
        <Skeleton variant="rectangular" width="100%" height={50} sx={{ borderRadius: '10px' }} />
      </div>
    )
  }

  switch (layoutType) {
    case FAQ_LAYOUTS.TWO_COLUMN_SIDEBAR:
      return isPC ? <ExpandedFAQSkeleton /> : defaultRender()
    default:
      return defaultRender()
  }
}

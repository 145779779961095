import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MonitorIcon from '@mui/icons-material/Monitor'
import { allAvailableEventTypes, SCREENING_STATUSES } from 'src/consumer/constants'

export default function ScreeningCheckUI({ bookedSlot }) {
  const allScreeningSteps = bookedSlot?.previousSteps?.filter(
    (step) => step?.refType === allAvailableEventTypes.screening,
  )

  function handleScreeningStatus(screeningStep) {
    console.log({ screeningStep })

    switch (screeningStep?.status) {
      case SCREENING_STATUSES.COMPLETED:
        return <CheckCircleIcon style={{ color: '#E5CCE7', fontSize: '16px' }} />
      case SCREENING_STATUSES.NOT_STARTED:
        return <CancelIcon style={{ color: 'red', fontSize: '16px' }} />
      case SCREENING_STATUSES.STARTED:
      default:
        return <MonitorIcon style={{ color: 'yellow', fontSize: '16px' }} />
    }
  }

  return allScreeningSteps?.length > 0 ? (
    <span>
      Screening:{' '}
      <span>
        {allScreeningSteps?.map((screeningStep) => {
          return handleScreeningStatus(screeningStep)
        })}
      </span>
    </span>
  ) : (
    <></>
  )
}

import { getDOB } from 'src/consumer/helpers/profilePageHelper'

export const prepareDataForRegistration = (data) => {
  const registrationData = {
    userName: data?.email,
    email: data?.email,
    password: data?.password,
  }

  let lat = null
  let lng = null
  try {
    const geoLocationString = localStorage.getItem('geoLocation')
    if (geoLocationString) {
      const geoLocation = JSON.parse(geoLocationString)
      if (geoLocation) {
        lat = geoLocation.lat
        lng = geoLocation.lng
      }
    }
  } catch (error) {
    console.error('Error parsing geoLocation from localStorage:', error)
  }

  const countryCode = localStorage.getItem('countryCode') || null

  const locationObject = {}

  if (lat !== null) locationObject['lat'] = lat
  if (lng !== null) locationObject['lng'] = lng
  if (countryCode) locationObject['countryCode'] = countryCode

  registrationData['location'] = locationObject

  return registrationData
}

export const prepareDataForUpdate = (data, profileData) => {
  console.log(data)
  console.log(profileData)

  return {
    ...profileData,
    firstName: data?.name,
    gender: data?.gender,
    dob: getDOB(data?.age),
    personalize: {
      studentPersonalize: {
        contentLanguages: data?.contentLanguages,
      },
    },
  }
}

export const prepareDataForPersonalize = (data, profileData) => {
  let studentPersonalizeObject = profileData.personalize.studentPersonalize

  return {
    ...studentPersonalizeObject,
    acquisitionId: data?.acquisitionMethod,
    primaryPath: data?.pathSelected,
    mentalHealthProfessional: {
      ...studentPersonalizeObject?.mentalHealthProfessional,
      iamMentalHealthProfessional: data?.wantProfessional === 'self' ? true : false,
      workingWithMentalHealthProfessional:
        data?.wantProfessional === 'self' ? null : data?.wantProfessional,
    },
    persona: {
      ...studentPersonalizeObject?.persona,
      bodyTopics: [],
      category: data?.categoriesSelected,
      focusAreas: data?.focusAreasSelected,
      mindTopics: [],
      soulTopics: [],
      primaryTopics: [],
      secondaryTopics: [],
    },
  }
}

import { QRCode } from 'react-qrcode-logo'
import { PMPFooterContainer, PMPThreeColumnFooter } from '../../PrescriptionMakingPageStyles'

export default function ThreeColumnPrescriptionFooter({
  prescriptionData,
  viewingType,
  printType,
  noColor,
  eventId,
  hideQr = true,
}) {
  function renderSingleColumn(footerData, lastItem) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '5px',
          borderRight: !lastItem && '1px solid #000',
        }}
      >
        {/* <div>Clinic:-</div> */}
        {footerData?.address && <div>{footerData?.address}</div>}
        {footerData?.timingsAltText && <div>{footerData?.timingsAltText}</div>}
        {footerData?.timings && <div>{footerData?.timings}</div>}
      </div>
    )
  }

  return (
    <>
      <PMPThreeColumnFooter noColor={noColor} viewingType={viewingType} printType={printType}>
        {renderSingleColumn(prescriptionData?.relatedClinicDetails?.[0])}
        {renderSingleColumn(prescriptionData?.relatedClinicDetails?.[1])}
        {renderSingleColumn(prescriptionData?.relatedClinicDetails?.[2])}
      </PMPThreeColumnFooter>
      <PMPFooterContainer
        noColor={noColor}
        viewingType={viewingType}
        printType={printType}
        threeColFooter
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-center',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '2px 7px' }}>
          <div style={{ textAlign: 'center' }}>{prescriptionData?.footer?.headline}</div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '25px',
            alignItems: 'flex-center',
            padding: '2px 7px',
          }}
        >
          <div style={{ textAlign: 'right' }}>{prescriptionData?.footer?.subtext}</div>
          <div style={{ textAlign: 'left' }}>{prescriptionData?.footer?.supportText}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {eventId && !hideQr && (
            <>
              <QRCode value={`https://aumhum.com/session/${eventId}/prescription`} size={100} />
              <div style={{ textAlign: 'center' }}>
                Scan the QR Code to view the digital copy of this prescription
              </div>
            </>
          )}
        </div>
      </PMPFooterContainer>
    </>
  )
}

import { Add, Delete, Edit } from '@mui/icons-material'
import { Box, Dialog, IconButton, Popover } from '@mui/material'
import { useEffect, useState } from 'react'
import ConfirmationModal, {
  CustomSelect,
  selectTypes,
} from 'src/consumer/components/helperComponents'
import { FEATURE_BUNDLE_TYPES_ARRAY } from 'src/consumer/constants'
import {
  confirmationModalDefaultProps,
  genericHandleChange,
} from 'src/consumer/helpers/utilFunctions'
import { GenericModalHeadlineLevelTwo } from 'src/mentor/components/MentorAvailabilityPage/MentorAvailabilityPageStyles'
import { ChangesWillNotBeSavedButton, StyledDialogPaper } from '../../MentorEventsPageStyles'
import BaseAddCustomPriceComponent from '../BaseAddCustomPriceComponent'
import {
  ActualBundlePacksContainer,
  BundlePackHeadingContainer,
  BundlePackItem,
} from './styledComponents'

export default function SelectABundle({
  selectedBundleIds,
  setSelectedBundleIds,
  refreshBundles,
  allBundles,
  allOneOnOnes,
}) {
  const [showAddCustomPrice, setShowAddCustomPrice] = useState(false)
  const [bundlePackToEdit, setBundlePackToEdit] = useState()
  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [reactSelectedBundle, setReactSelectedBundle] = useState()
  const [confirmationProps, setConfirmationProps] = useState(confirmationModalDefaultProps)

  function addBundle(event) {
    setPopoverAnchor(event.currentTarget)
  }

  function addBundleToList(bundle) {
    if (reactSelectedBundle?.id) {
      setSelectedBundleIds((prev) => [reactSelectedBundle?.id, ...prev])
    }
    closeAddBundlePopover()
  }

  function createBundle() {
    setShowAddCustomPrice(true)
    setBundlePackToEdit({ bundle: [{ type: FEATURE_BUNDLE_TYPES_ARRAY?.[0]?.id, maxCount: 1 }] })
  }

  function deleteBundle(idx) {
    setConfirmationProps((prev) => ({
      ...prev,
      action: 'delete',
      visibility: 'true',
      visibilitySetter: setConfirmationProps,
      body: 'Are you sure you want to delete this bundle?',
      buttonColor: 'error',
      onSubmitFunctions: [
        () => {
          setSelectedBundleIds((prev) => {
            const newBundleIds = [...prev]
            newBundleIds.splice(idx, 1)
            return newBundleIds
          })
        },
      ],
    }))
  }

  function editBundle(idx) {
    // show the bundle pack modal
    const foundBundleId = selectedBundleIds?.[idx]
    const foundBundle = allBundles?.find((bundle) => bundle?.id === foundBundleId)
    setBundlePackToEdit(foundBundle)
    setShowAddCustomPrice(true)
  }

  function handleCloseAddCustomPrice() {
    setShowAddCustomPrice(false)
  }

  function closeAddBundlePopover() {
    setPopoverAnchor(null)
    setReactSelectedBundle(null)
  }

  useEffect(() => {
    console.log(selectedBundleIds)
  }, [selectedBundleIds])

  return (
    <>
      <BundlePackHeadingContainer>
        <GenericModalHeadlineLevelTwo>Current Bundles</GenericModalHeadlineLevelTwo>
        <IconButton onClick={addBundle}>
          <Add />
        </IconButton>
      </BundlePackHeadingContainer>
      <ActualBundlePacksContainer>
        {selectedBundleIds?.length > 0 ? (
          selectedBundleIds?.map((bdlId, idx) => {
            const foundBundle = allBundles?.find((bundle) => bundle?.id === bdlId)
            return (
              <BundlePackItem key={bdlId}>
                <span>{foundBundle?.title}</span>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
                  <IconButton onClick={() => deleteBundle(idx)}>
                    <Delete />
                  </IconButton>
                  <IconButton onClick={() => editBundle(idx)}>
                    <Edit />
                  </IconButton>
                </Box>
              </BundlePackItem>
            )
          })
        ) : (
          <span>No bundles selected</span>
        )}
      </ActualBundlePacksContainer>

      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={closeAddBundlePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2, width: '350px' }}>
          <GenericModalHeadlineLevelTwo>Add a bundle</GenericModalHeadlineLevelTwo>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '5px' }}>
            <CustomSelect
              id="bundle-select"
              options={allBundles?.filter((item) => !selectedBundleIds?.includes(item?.id))}
              value={reactSelectedBundle}
              placeholder="Select your existing bundles"
              onChangeEvent={(eve) => setReactSelectedBundle(eve.target.value)}
              selectType={selectTypes.object}
              getOptionLabel={(option) => option?.title}
              getOptionValue={(option) => option?.id}
            />
            <strong>or</strong>
            <ChangesWillNotBeSavedButton onClick={createBundle} sx={{ width: '100%' }}>
              Create a Bundle
            </ChangesWillNotBeSavedButton>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', mt: 4 }}>
            <ChangesWillNotBeSavedButton
              sx={{ background: '#415dfc' }}
              disabled={!reactSelectedBundle}
              onClick={addBundleToList}
            >
              Add Bundle to this event
            </ChangesWillNotBeSavedButton>
          </Box>
        </Box>
      </Popover>

      <Dialog
        open={showAddCustomPrice}
        onClose={handleCloseAddCustomPrice}
        PaperComponent={StyledDialogPaper}
      >
        <BaseAddCustomPriceComponent
          selectedPlan={bundlePackToEdit}
          setSelectedPlan={setBundlePackToEdit}
          handleChangePlan={(eve) => genericHandleChange(eve, setBundlePackToEdit)}
          closeFunction={handleCloseAddCustomPrice}
          allOneOnOnePlans={allOneOnOnes}
          refreshOneOnOnePlans={refreshBundles}
          type="bundle"
        />
      </Dialog>
      <ConfirmationModal newImplementation {...confirmationProps} />
    </>
  )
}

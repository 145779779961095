import { apiURL, commonHeadersWeb, platformWeb } from 'src/consumer/services'
import axios from 'axios'

export const getFaq = async (searchString, params = {}) => {
  try {
    const response = await axios.get(`${apiURL}/api/faq/response?categoryIds=${searchString}`, {
      headers: {
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
      params,
    })
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}

import chroma from 'chroma-js'
import parse from 'html-react-parser'
import moment from 'moment'
import { Helmet } from 'react-helmet-async'
import { isValidEmail } from 'src/clinic/views/onboardng/clinicBrand/components/utils'
import { APP_CONSTANTS, STRING_ID_TYPES } from '../constants'

const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const nameRegExp = /^[a-zA-Z\s]+$/
const onlyNumbersRegExp = /^[0-9]+$/

export const openInNewTab = (url) => {
  window.open(url, '_blank')
}

export function formatNumberWithMinTwoDigits(num) {
  return num?.toString()?.padStart(2, '0')
}

export function validateEmail(emailString) {
  if (emailRegExp.test(emailString)) {
    return true
  }
  return false
}

export function validateName(nameString) {
  if (nameRegExp.test(nameString)) {
    return true
  }
  return false
}

export function validateOnlyNumbers(nameString) {
  if (onlyNumbersRegExp.test(nameString)) {
    return true
  }
  return false
}

export function formatDateForPosts(inputDate) {
  const today = moment()
  const date = moment(inputDate, 'YYYY-MM-DD HH:mm:ss')

  if (date.isSame(today, 'day')) {
    return 'Today'
  } else if (date.isSame(today, 'year')) {
    return date.format('MMM D')
  } else {
    return date.format('MMM D, YYYY')
  }
}

export function countWordsInHTML(htmlString) {
  // Remove HTML tags and extract text content
  const textContent = htmlString.replace(/<[^>]*>/g, '')
  // Split the text into words using whitespace as a delimiter
  const words = textContent.split(/\s+/)
  // Count the number of words
  return words.length
}

export function calculateReadTimeFromWords(words) {
  const wpm = 130
  const toReturn = Math.floor(parseInt(words) / wpm)
  if (toReturn > 0) {
    return toReturn
  } else {
    return 1
  }
}

export function generateUidForAgora() {
  const minUserID = 1
  const maxUserID = Math.pow(2, 32) - 1

  // Generate a random integer within the specified range
  const uid = Math.floor(Math.random() * (maxUserID - minUserID + 1)) + minUserID

  return uid // Convert the integer to a string
}

export const SEOComponent = ({ title, description, image, keywordsArr }) => {
  let keyWordsArray = [
    ...(Array.isArray(keywordsArr) ? keywordsArr : []),
    ...(APP_CONSTANTS.DEFAULT_KEYWORDS || []),
  ]
  const uniqueKeywordsSet = new Set(keyWordsArray)

  const titleString = title || APP_CONSTANTS.DEFAULT_TITLE
  const keywordsString = Array.from(uniqueKeywordsSet).join(', ')
  const descriptionString = description || APP_CONSTANTS.DEFAULT_DESCRIPTION
  const imageLink = image || APP_CONSTANTS.APP_LOGO_LINK

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={descriptionString} />
      <meta name="keywords" content={keywordsString} />

      {/* Open Graph Protocol (OGP) meta tags */}
      {/* <meta property="og:type" content={type} /> */}
      <meta property="og:title" content={titleString} />
      <meta property="og:description" content={descriptionString} />
      <meta property="og:image" content={imageLink} />

      {/* Twitter Card meta tags */}
      {/* <meta name="twitter:creator" content={name} /> */}
      {/* <meta name="twitter:card" content={type} /> */}
      <meta name="twitter:title" content={titleString} />
      <meta name="twitter:description" content={descriptionString} />
      <meta name="twitter:image" content={imageLink} />
    </Helmet>
  )
}

export function calculateMinWidthAndHeight(decimalAspectRatio) {
  if (decimalAspectRatio <= 0) {
    throw new Error('Aspect ratio must be a positive number.')
  }

  // Calculate the minimum width and height
  const gcd = (a, b) => (b < 1e-5 ? a : gcd(b, a % b))
  const denominator = 1 / decimalAspectRatio
  const minWidth = 1 / gcd(1, denominator)
  const minHeight = denominator / gcd(1, denominator)

  return { minWidth, minHeight }
}

export function checkAndGetTargetValue(e) {
  switch (e.target.type) {
    case 'checkbox':
      return e.target.checked
    case 'number':
      return e.target.value === '' ? null : Number(e.target.value)

    default:
      return e.target.value
  }
}

export function getFCMToken() {
  return localStorage.getItem('FCMToken')
}

export function removePlusFromBeginning(inputString) {
  if (inputString) {
    if (inputString?.startsWith('+')) {
      return inputString?.substring(1)
    } else {
      return inputString
    }
  }
}

export function identifyUUIDOrSlugType(str) {
  const uuidRegex = /^[a-zA-Z0-9]{3}-[0-9a-fA-F]{32}$/
  if (uuidRegex.test(str)) {
    return STRING_ID_TYPES.uuid
  } else {
    return STRING_ID_TYPES.slug
  }
}

export function separateItemsInArray(onBasisOfField, array) {
  const toReturn = {}
  for (let i = 0; i < array.length; i++) {
    // checking and adding categoryId to toReturn
    if (!toReturn.hasOwnProperty(array[i][onBasisOfField])) {
      toReturn[array[i][onBasisOfField]] = []
    }
    toReturn[array[i][onBasisOfField]] = [...toReturn[array[i][onBasisOfField]], array[i]]
  }
  return toReturn
}

export const confirmationModalDefaultProps = {
  visibility: false,
  visibilitySetter: null,
  action: '',
  body: '',
  buttonColor: 'success',
  onSubmitFunctions: [],
}

export function generateHexWithOpacity(opacity = 1) {
  // Generate a random color
  const randomColor = chroma.random()

  // Set the desired opacity
  const colorWithOpacity = chroma(randomColor).alpha(opacity)

  // Convert to hex and return
  return colorWithOpacity.hex()
}

function isColorUnique(color, existingColors) {
  // Check if the color is not similar to any existing color
  return !existingColors.some((existingColor) => chroma.deltaE(existingColor, color) < 20) // Adjust the threshold as needed
}

/**
 * Generate a hex color code not similar to existing colors.
 * @param {number} opacity - Opacity value between 0 and 1 (default is 1).
 * @param {string[]} existingColors - Array of existing hex color codes.
 * @returns {string} Hex color code.
 */
export function generateUniqueHexWithOpacity(opacity = 1, existingColors = []) {
  let generatedColor

  // Retry until a color is found that is not similar to any in the existingColors array
  while (true) {
    generatedColor = generateHexWithOpacity(opacity)

    // Check if the generated color is not similar to any existing color
    const isUnique = isColorUnique(generatedColor, existingColors)

    if (isUnique) {
      break
    }
  }

  return generatedColor
}

export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}

export function sortFn(obj1, obj2) {
  if (obj1 === obj2) {
    return 0
  } else {
    return obj1 > obj2 ? 1 : -1
  }
}

export function checkIfStringIsValid(str) {
  if (typeof str !== 'string') {
    return false
  }
  return str.length > 0
}

export function isValidNumber(value) {
  return typeof value === 'number' && Number.isFinite(value)
}

export function isValidInteger(value) {
  return isValidNumber(value) && Number.isInteger(value)
}

export function getWhatsAppShareLink(text) {
  const isMobileOrTablet = /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)

  if (isMobileOrTablet) {
    return `https://${isMobileOrTablet ? 'api' : 'web'}.whatsapp.com/send?text=${text}`
  } else {
    const userAgent = window.navigator.userAgent.toLowerCase()
    const isWhatsappInstalled = userAgent.includes('whatsapp')
    return `${isWhatsappInstalled ? 'whatsapp://?' : 'https://web.whatsapp.com/'}send?text=${text}`
  }
}

export function checkIfStringIsASlugOrId(str) {}

export function addToGoogleCalendarLink(
  utcStartDateTime,
  utcEndDateTime,
  name,
  description,
  moment,
) {
  const startDateString = moment?.utc(utcStartDateTime)?.format('YYYYMMDDTHHmmss[Z]')
  const endDateString = moment?.utc(utcEndDateTime)?.format('YYYYMMDDTHHmmss[Z]')
  const googleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${name}&details=${description}&dates=${startDateString}/${endDateString}`
  return googleCalendarLink
}

export function genericHandleChange(event, setter) {
  if (event.target.id)
    setter((prev) => ({
      ...prev,
      [event.target.id]: checkAndGetTargetValue(event),
    }))
  else {
    setter(checkAndGetTargetValue(event))
  }
}

export function getCorrectTargetValueForEvent(e, overrideNumberTransform = false) {
  switch (e.target.type) {
    case 'checkbox':
      return e.target.checked
    case 'number':
      if (overrideNumberTransform) {
        return e.target.value
      }
      return e.target.value === '' ? null : Number(e.target.value)

    default:
      return e.target.value
  }
}

export function giveGenericHandleChangedData(
  eventObjOrPrevFuncOrValueToSet,
  prev,
  overrideNumberTransform,
  objectIsNotEventObject = false,
) {
  if (typeof eventObjOrPrevFuncOrValueToSet === 'function') {
    return eventObjOrPrevFuncOrValueToSet(prev)
  } else {
    if (objectIsNotEventObject) {
      return eventObjOrPrevFuncOrValueToSet
    } else {
      if (eventObjOrPrevFuncOrValueToSet.target.id)
        return {
          ...prev,
          [eventObjOrPrevFuncOrValueToSet.target.id]: getCorrectTargetValueForEvent(
            eventObjOrPrevFuncOrValueToSet,
            overrideNumberTransform,
          ),
        }
      else {
        return getCorrectTargetValueForEvent(eventObjOrPrevFuncOrValueToSet)
      }
    }
  }
}

export function handleEventTargetChange(prev, event) {
  return {
    ...prev,
    [event.target.id]: getCorrectTargetValueForEvent(event),
  }
}

export function genericChangeNestedFields(value, outerId, setter) {
  setter((prev) => ({
    ...prev,
    [outerId]: typeof value === 'function' ? value(prev?.[outerId]) : value,
  }))
}

export function genericHandleNestedFields(event, outerId, setState) {
  if (typeof event === 'function') {
    genericChangeNestedFields((prev) => event(prev), outerId, setState)
  } else {
    genericChangeNestedFields((prev) => handleEventTargetChange(prev, event), outerId, setState)
  }
}

export function genericHandleNestedNestedFields(event, outerId, outerOuterId, setter) {
  setter((prev) => ({
    ...prev,
    [outerOuterId]: {
      ...(prev?.[outerOuterId] || {}),
      [outerId]: {
        ...(prev?.[outerOuterId]?.[outerId] || {}),
        [event.target.id]: giveGenericHandleChangedData(event, prev?.[outerOuterId]?.[outerId]),
      },
    },
  }))
}

export function titleCaseWithExceptionWords(str, exceptions = []) {
  // Split the string into words
  const words = str.split(' ')

  // Convert each word to title case except the exceptions
  const titleCaseWords = words.map((word) => {
    // Check if the word is in exceptions
    if (exceptions.includes(word.toLowerCase())) {
      return word // Leave the word as is
    } else {
      // Convert the word to title case
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }
  })

  // Join the title case words back into a string
  return titleCaseWords.join(' ')
}

export function getParseDataFromHtmlString(htmlString, { format = true }) {
  let formattedString = htmlString
  if (format) {
    formattedString = htmlString?.replace(/<p><\/p>\n/g, '<br>') || ''
  }
  return parse(formattedString)
}

export function giveMediaTypeBasedOnMimeType(mimeType) {
  if (mimeType.includes('image')) {
    return 'image'
  } else if (mimeType.includes('video')) {
    return 'video'
  } else if (mimeType.includes('audio')) {
    return 'audio'
  } else {
    return 'file'
  }
}

export function getVideoFileMetaData(file) {
  return new Promise((resolve, reject) => {
    var video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src)
      resolve({
        duration: video.duration,
        width: video.videoWidth,
        height: video.videoHeight,
      })
    }

    video.onerror = function () {
      reject('Error loading video file')
    }

    video.src = URL.createObjectURL(file)
  })
}

export function filterValidAndNonRepeatedEmails(emails) {
  return [...new Set(emails || [])].filter((email) => !!email && isValidEmail(email))
}

export function areStringsEqualIgnoreCase(str1, str2) {
  if (str1 === undefined || str1 === null || str2 === undefined || str2 === null) {
    return false
  }
  return str1.toLowerCase() === str2.toLowerCase()
}

import { GENDER_LIST_ONBOARDING } from 'src/consumer/constants'
import { getAgeFromDobWithTime } from 'src/consumer/helpers/profilePageHelper'
import { getDateOfPrescription } from '../../PrescriptionMakingPageComponents'
import {
  PMPHeaderDetails,
  PMPHeaderDoctorText,
  PMPHeaderLeftPart,
  PMPHeaderRightPart,
  PMPHeadingBanner,
} from '../../PrescriptionMakingPageStyles'

export default function ThreeColumnPrescriptionHeader({
  prescriptionData,
  viewingType,
  printType,
}) {
  return (
    <div style={{ background: '#FFF' }}>
      <PMPHeadingBanner noColor={true} printType={printType}>
        {prescriptionData?.header?.title}
      </PMPHeadingBanner>

      <div
        style={{
          justifyContent: 'space-between',
          display: `${printType === 'withHeadingAndBottomInformation' ? 'flex' : 'none'}`,
        }}
      >
        <PMPHeaderLeftPart viewingType={viewingType}>
          <PMPHeaderDoctorText main viewingType={viewingType}>
            {prescriptionData?.doctorName}
          </PMPHeaderDoctorText>
          <PMPHeaderDoctorText viewingType={viewingType}>
            {prescriptionData?.designation}
          </PMPHeaderDoctorText>
          <PMPHeaderDoctorText viewingType={viewingType}>
            {prescriptionData?.listOfDegrees?.join(', ')}
          </PMPHeaderDoctorText>
        </PMPHeaderLeftPart>
        <PMPHeaderRightPart
          style={{
            flexShrink: 0,
            width: 'unset',
          }}
          viewingType={viewingType}
        >
          {prescriptionData?.regId && (
            <PMPHeaderDoctorText viewingType={viewingType}>
              <b>Regn No.: {prescriptionData?.regId}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.contactEmail && (
            <PMPHeaderDoctorText viewingType={viewingType}>
              Email: <b>{prescriptionData?.contactEmail}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.website && (
            <PMPHeaderDoctorText viewingType={viewingType}>
              Website: <b>{prescriptionData?.website}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.cmoRegId && (
            <PMPHeaderDoctorText viewingType={viewingType}>
              <b>C.M.O. Regd. No.: {prescriptionData?.cmoRegId}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.affiliations?.map((affiliation, affIndex) => (
            <PMPHeaderDoctorText viewingType={viewingType} key={affIndex}>
              <b>{affiliation}</b>
            </PMPHeaderDoctorText>
          ))}
        </PMPHeaderRightPart>
      </div>

      <PMPHeaderDetails
        noColor={true}
        viewingType={viewingType}
        printType={printType}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <div>
          Patient Name:{' '}
          <span style={{ textTransform: 'capitalize' }}>
            {prescriptionData?.patientPrefill?.name} (
            {getAgeFromDobWithTime(prescriptionData?.patientPrefill?.vital?.dateOfBirth)}/
            {
              GENDER_LIST_ONBOARDING?.find(
                (item) => item.value === prescriptionData?.patientPrefill?.vital?.gender,
              )?.label
            }
            )
          </span>
        </div>
        <div style={{ textAlign: 'right' }}>
          Date : {getDateOfPrescription(prescriptionData, true)}
        </div>
      </PMPHeaderDetails>
    </div>
  )
}

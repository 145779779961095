import { GENDER_LIST_ONBOARDING } from 'src/consumer/constants'
import { getAgeFromDobWithTime } from 'src/consumer/helpers/profilePageHelper'
import { getDateOfPrescription } from '../../PrescriptionMakingPageComponents'
import {
  PMPHeaderDetails,
  PMPHeaderDoctorText,
  PMPHeaderLeftPart,
  PMPHeaderRightPart,
  PMPHeadingBanner,
} from '../../PrescriptionMakingPageStyles'

export default function DefaultPrescriptionHeader({ prescriptionData, viewingType, printType }) {
  return (
    <div style={{ background: '#FFF' }}>
      <PMPHeadingBanner noColor={true} printType={printType}>
        {prescriptionData?.header?.title}
      </PMPHeadingBanner>

      <div
        style={{
          justifyContent: 'space-between',
          display: `${printType === 'withHeadingAndBottomInformation' ? 'flex' : 'none'}`,
        }}
      >
        <PMPHeaderLeftPart viewingType={viewingType}>
          {prescriptionData?.regId && (
            <PMPHeaderDoctorText viewingType={viewingType}>
              <b>Regn No.: {prescriptionData?.regId}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.contactEmail && (
            <PMPHeaderDoctorText viewingType={viewingType}>
              Email: <b>{prescriptionData?.contactEmail}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.website && (
            <PMPHeaderDoctorText viewingType={viewingType}>
              Website: <b>{prescriptionData?.website}</b>
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.cmoRegId && (
            <PMPHeaderDoctorText viewingType={viewingType}>
              <b>C.M.O. Regd. No.: {prescriptionData?.cmoRegId}</b>
            </PMPHeaderDoctorText>
          )}
        </PMPHeaderLeftPart>
        <PMPHeaderRightPart viewingType={viewingType}>
          <PMPHeaderDoctorText main textRight={true} viewingType={viewingType}>
            {prescriptionData?.doctorName}
          </PMPHeaderDoctorText>
          <PMPHeaderDoctorText textRight={true} viewingType={viewingType}>
            {prescriptionData?.designation}
          </PMPHeaderDoctorText>
          <PMPHeaderDoctorText textRight={true} viewingType={viewingType}>
            {prescriptionData?.listOfDegrees?.join(', ')}
          </PMPHeaderDoctorText>
          {prescriptionData?.affiliations?.map((affiliation, affIndex) => (
            <PMPHeaderDoctorText textRight={true} viewingType={viewingType} key={affIndex}>
              <b>{affiliation}</b>
            </PMPHeaderDoctorText>
          ))}
        </PMPHeaderRightPart>
      </div>

      <PMPHeaderDetails noColor={true} viewingType={viewingType} printType={printType}>
        <div>
          Patient Name:{' '}
          <span style={{ textTransform: 'capitalize' }}>
            {prescriptionData?.patientPrefill?.name}
          </span>
        </div>
        <div>
          Age/Gender: {getAgeFromDobWithTime(prescriptionData?.patientPrefill?.vital?.dateOfBirth)}/
          {
            GENDER_LIST_ONBOARDING?.find(
              (item) => item.value === prescriptionData?.patientPrefill?.vital?.gender,
            )?.label
          }
        </div>
        <div>Date : {getDateOfPrescription(prescriptionData, true)}</div>
      </PMPHeaderDetails>
    </div>
  )
}

import moment from 'moment'
import { toast } from 'react-toastify'
import { GENDER_LIST_ONBOARDING, defaultBgImgId } from 'src/consumer/constants'
import { UNSPLASH_API, appVersion, mediaURL, platformWeb } from 'src/consumer/services'
import { getMPData, postMPDataWithoutParams } from '../services'

export const isValidHttpUrl = (string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const logoutHandlerMentor = () => {
  let lanCode = sessionStorage.getItem('languageCode')
  sessionStorage.clear()
  localStorage.removeItem('token')
  localStorage.removeItem('authorities')
  localStorage.removeItem('FCMToken')
  localStorage.removeItem('submittedFCMTokenForThisUser')
  sessionStorage.setItem(
    'languageCode',
    lanCode ? lanCode : navigator?.language || navigator?.userLanguage.split('-')[0],
  )
}

export const getImageLink = (priorityImage, secondaryImage, returnFallBack = true) => {
  let image
  if (priorityImage) {
    if (isValidHttpUrl(priorityImage)) {
      image = priorityImage
    } else {
      image = `${mediaURL}${priorityImage}`
    }
  } else if (secondaryImage) {
    if (isValidHttpUrl(secondaryImage)) {
      image = secondaryImage
    } else {
      image = `${mediaURL}${secondaryImage}`
    }
  } else if (returnFallBack) {
    image = `${mediaURL}${defaultBgImgId}`
  }

  return image
}

export const getImageLinkWithUnsplash = async (
  priorityImage,
  secondaryImage,
  { useUnsplashImage = false, unSplashParamsOverride = {} },
) => {
  let image = getImageLink(priorityImage, secondaryImage, false)
  if (useUnsplashImage && !isValidHttpUrl(image)) {
    image = await UNSPLASH_API.photos
      .getRandom({
        query: 'nature',
        orientation: 'landscape',
        topicIds: ['nature', 'lakes'],
        contentFilter: 'high',
        ...unSplashParamsOverride,
      })
      .then((result) => {
        if (result.status === 200) {
          return result.response?.urls?.regular
        } else {
          return getImageLink(priorityImage, secondaryImage)
        }
      })
      .catch((err) => {
        console.log(err)
        return getImageLink(priorityImage, secondaryImage)
      })
  }
  return image
}

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

export const getHoursAndMinutesFromMinutes = (minutes) => {
  const duration = moment.duration(minutes, 'minutes')
  const hours = duration.hours()
  const mins = duration.minutes()

  if (hours === 0) {
    return `${mins} mins`
  } else if (mins === 0) {
    return `${hours} hrs`
  } else {
    return `${hours} hrs ${mins} mins`
  }
}

export function isBasicOnboardingDone(profileData) {
  const arrayOfGenderIds = GENDER_LIST_ONBOARDING?.map((item, index) => {
    return item?.value
  })

  if (
    profileData?.personalize?.studentPersonalize?.contentLanguages?.length === 0 ||
    !Boolean(profileData?.dob) ||
    !Boolean(profileData?.name) ||
    !Boolean(arrayOfGenderIds?.includes(profileData?.gender))
  ) {
    return false
  }
  return true
}

export function isHtmlEmpty(htmlString) {
  // Remove HTML tags and extra spaces
  const textContent = htmlString.replace(/<[^>]*>/g, '').trim()

  // Check if the remaining text content is empty
  return textContent === ''
}

export function linkifyTheText(text) {
  if (text) {
    // Regular expression to match URLs
    var urlRegex = /(https?:\/\/[^\s]+)/g
    // Replace URLs with anchor tags
    var replacedText = text.replaceAll(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>'
    })
    return replacedText
  }
}

export function boldifyAsteriskText(text) {
  if (text) {
    // Regular expression to match text enclosed within asterisks
    const boldRegex = /\*([^*]+)\*/g

    // Replace matched text with bolded HTML tags
    const replacedText = text.replace(boldRegex, '<b>$1</b>')

    return replacedText
  }
}

export async function markBookedSessionAsCompletedFunction(bookedEventData) {
  const response = await postMPDataWithoutParams(
    `/api/secure/teacher/booked-event/finish?bookedEventId=${bookedEventData?.id}`,
    {},
  )
  if (response?.status === 200) {
    toast.success('Session marked completed')
  }
  return response
}

export function getEventsNotInAnySchedule(data) {
  if (Array.isArray(data?.eventTypes) && data.eventTypes.length > 0) {
    const filteredEvents = data?.eventTypes?.filter((eventTypeBeingChecked) => {
      return !Boolean(
        data?.schedules?.some((schedule) =>
          schedule?.eventIds?.includes(eventTypeBeingChecked?.uid),
        ),
      )
    })
    return filteredEvents
  } else {
    return []
  }
}

export function getEventsArrayFromEventIdsArray(eventIdsArray, data) {
  const arrayToReturn = []
  if (Array.isArray(eventIdsArray) && eventIdsArray.length > 0) {
    eventIdsArray?.forEach((eventId) => {
      const foundEventTypeData = data?.eventTypes?.find((eventType) => eventType?.uid === eventId)
      if (foundEventTypeData) {
        arrayToReturn.push(foundEventTypeData)
      }
    })
  }
  return arrayToReturn
}

export async function getSubscriptionPlanById(planId) {
  const response = await getMPData(
    `/api/subscription-plan?id=${planId}&zoneOffset=${encodeURIComponent(
      moment().format('Z'),
    )}&appVersion=${appVersion}&platformType=${platformWeb}&languageCode=${sessionStorage.getItem(
      'languageCode',
    )}&platform=${platformWeb}&countryCode=${localStorage.getItem('countryCode')}`,
  )
  return response
}

export function getDefaultScheduleFromSchedules(schedules) {
  let toReturn
  toReturn = schedules?.find((schedule) => schedule?.defaultSchedule)
  if (!Boolean(toReturn)) {
    toReturn = schedules?.[0]
  }
  return toReturn
}

export function isCommonResponseSuccessful(codeReceived) {
  if (codeReceived === 200 || codeReceived === 2000 || codeReceived === 0) {
    return true
  }
  return false
}

export function getClinicBrandIdFromClinicId(clinicsDataArray, clinicId) {
  const thisClinic = clinicsDataArray.find((item) => item.id === clinicId)
  if (thisClinic.clinicBrandId) {
    return thisClinic.clinicBrandId
  }
  return ''
}

export function getStringFromHTMLString(htmlString) {
  const tmpElement = document.createElement('div')
  tmpElement.innerHTML = htmlString
  return tmpElement.textContent || tmpElement.innerText || ''
}

// export function MultipleInheritance(bases) {
//   class Bases {
//     constructor() {
//       bases.forEach((base) => Object.assign(this, new base()))
//     }
//   }
//   bases.forEach((base) => {
//     Object.getOwnPropertyNames(base.prototype)
//       .filter((prop) => prop != 'constructor')
//       .forEach((prop) => (Bases.prototype[prop] = base.prototype[prop]))
//   })
//   return Bases
// }

export function MultipleInheritance(bases) {
  // Define a new class that extends from the provided base classes
  class Combined extends bases[0] {
    constructor(...args) {
      super(...args) // Call the constructor of the first base class
      // Call constructors of other base classes and inherit their properties
      for (let i = 1; i < bases.length; i++) {
        const base = bases[i]
        new base(...args)
      }
    }
  }

  // Inherit prototype properties and methods from all base classes
  bases.forEach((base) => {
    Object.getOwnPropertyNames(base.prototype)
      .filter((prop) => prop !== 'constructor')
      .forEach((prop) => {
        Combined.prototype[prop] = base.prototype[prop]
      })
  })

  return Combined
}

export function getInitialsForChat(name) {
  const givenName = name || 'User'
  const nameArray = givenName.split(' ')
  let str = ''
  nameArray?.forEach((word) => {
    str = str + word[0]
  })
  return str
}

export function maskNameForClinicCounter(name) {
  let toReturn = ''
  if (name) {
    const nameParts = name.split(' ')
    nameParts.forEach((word) => {
      if (word.length > 2) {
        toReturn += word.substring(0, 2) + '*'.repeat(word.length - 2) + ' '
      } else {
        toReturn += word + ' '
      }
    })
  }
  return toReturn
}


import { useEffect, useState } from 'react'
import { getFaq } from 'src/consumer/services/getFaq'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import FaqUIController from './faqComponents/FaqUIController'
import { FAQ_LAYOUTS } from './faqComponents/utils'
import { formFAQDataWithRespectiveFAQCategories } from './faq_utils'
import './styles/faqSectionStyles.css'

const FaqSection = ({
  categoryIds,
  fullWidth = false,
  scopeId,
  layoutType = FAQ_LAYOUTS.DEFAULT,
}) => {
  const [faqData, setFaqData] = useState()
  const [loading, setLoading] = useState(true)

  async function getAndSetFaqData() {
    setLoading(true)
    let searchString = ''
    if (Array.isArray(categoryIds) && categoryIds.length > 0) {
      searchString = categoryIds.join(',')
    }
    if (searchString) {
      let response = await getFaq(searchString, { withCategoryDetails: true, size: 1 })
      if (response?.status === 200 && isCommonResponseSuccessful(response.data.code)) {
        const faqData = response?.data?.data?.faqPage?.content
        const faqCategoriesData = response?.data?.data?.faqCategoryList
        var formedFaqData = formFAQDataWithRespectiveFAQCategories(faqData, faqCategoriesData)
        if (scopeId) {
          console.log({
            sorted: formedFaqData?.sort(
              (a, b) => a?.scopeRank?.[scopeId] - b?.scopeRank?.[scopeId],
            ),
          })

          formedFaqData = formedFaqData?.sort(
            (a, b) => a?.scopeRankMap?.[scopeId] - b?.scopeRankMap?.[scopeId],
          )
        }
        setFaqData(formedFaqData)
      } else {
        console.log('Error getting FaqData')
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    getAndSetFaqData()
  }, [categoryIds])

  return (
    <>
      <FaqUIController
        categories={faqData?.map((faqData) => {
          const { faqs, ...rest } = faqData
          return {
            ...rest,
          }
        })}
        loading={loading}
        layoutType={layoutType}
      />
    </>
  )
}

export default FaqSection

import { appInstallationId, appVersion, platformWeb } from 'src/consumer/services'
import axios from 'axios'
import { APP_CONSTANTS, TOAST_UTILS } from 'src/consumer/constants'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export const mentorApiURL = localStorage.getItem('apiURL') || `${APP_CONSTANTS.API_CALLS_DOMAIN}`

export const commonHeadersMPWeb = {
  'ef-app-version': appVersion,
  'ef-app-platform': platformWeb,
  'ef-app-package': 'com.aumhum.mentor',
  'ef-app-installation-id': appInstallationId,
  'ef-device-model': 'iPad11,6',
  'ef-device-os-version': '16',
  'ef-device-manufacturer': 'apple',
  'ef-session-id': sessionStorage.getItem('sessionId'),
  'ef-user-city': 'moscow',
  'ef-device-os-language': (navigator?.language || navigator?.userLanguage).split('-')[0],
}

export const useFetchMP = (path, customParams = '', platformType) => {
  const [state, setState] = useState({ loading: true, data: null, error: null })

  const [countryCode, setCountryCode] = useState(localStorage.getItem('countryCode'))

  useEffect(() => {
    function handleChangeStorage() {
      setCountryCode(localStorage.getItem('countryCode'))
    }

    window.addEventListener('storage', handleChangeStorage)
    return () => window.removeEventListener('storage', handleChangeStorage)
  }, [])

  const languageCode = sessionStorage.getItem('languageCode')
  const token = localStorage.getItem('token')

  useEffect(() => {
    setState({ loading: true, data: null, error: null })

    const paramsWeb = `languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode?.toLowerCase()}`

    let params = paramsWeb

    axios
      .get(`${mentorApiURL}${path}?${params}${customParams}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          ...commonHeadersMPWeb,
          'ef-user-country-code': localStorage.getItem('countryCode')?.toLowerCase(),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      })
      .then((res) => {
        setState({ data: res.data, loading: false, error: null })
      })
      .catch((error) => {
        setState({ data: null, loading: false, error: error })
      })
  }, [path, customParams, token, countryCode])
  return state
}

export async function getMPDataWithoutParams(url) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.get(`${mentorApiURL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...commonHeadersMPWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function postMPDataWithoutParams(url, data) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.post(`${mentorApiURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...commonHeadersMPWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}
export async function postMPDataWithParams(url, data, params = {}, loadingTst) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.post(`${mentorApiURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...commonHeadersMPWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
      params: { ...params },
    })
    return res
  } catch (err) {
    console.log(err)
    toast.update(loadingTst, {
      ...TOAST_UTILS,
      render: 'Something went wrong',
      type: toast.TYPE.ERROR,
      isLoading: false,
    })
    return err
  }
}

export async function getMPData(url) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(
      `${mentorApiURL}${url}?languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...commonHeadersMPWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getMPDataWithParams(url, apiParams) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(`${mentorApiURL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...commonHeadersMPWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
      params: {
        languageCode: languageCode,
        platform: platformWeb,
        countryCode: countryCode,
        ...apiParams,
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function postMPData(url, data) {
  const token = localStorage.getItem('token')
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.post(
      `${mentorApiURL}${url}?languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...commonHeadersMPWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export function getToken() {
  const token = localStorage.getItem('token')
  return token
}

export async function putMPDataWithoutParams(url, data) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.put(`${mentorApiURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...commonHeadersMPWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function putMPDataWithParams(url, data, params = {}) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.put(`${mentorApiURL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...commonHeadersMPWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
      params,
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

import { DefaultNextVisitRowComponent } from './DefaultBodyComponents'

export function ThreeColumnNextVisitRowComponent({
  nextVisitInputValue,
  nextVisitInputType,
  prescriptionData,
}) {
  return (
    <div className="d-flex justify-content-between">
      <DefaultNextVisitRowComponent
        nextVisitInputType={nextVisitInputType}
        nextVisitInputValue={nextVisitInputValue}
        prescriptionData={prescriptionData}
      />
    </div>
  )
}
